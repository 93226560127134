import { CheckOutlined, FileExclamationOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { Progress, Upload } from 'antd'
import { DraggerProps } from 'antd/lib/upload'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { logger } from '@publica/ui-common-logger'
import { colors } from '@publica/ui-common-styles'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { isFile } from '@publica/utils'

const useUploadWellStyles = createUseStyles({
    icon: {
        fontSize: 48,
        color: colors.primary,
    },
    instructions: {
        margin: [[10, 0], '!important'],
    },
    processing: {
        textAlign: 'center',
        color: colors.primary,
        fontSize: '4em',
    },
})

export type UploadedFileRequest = {
    file: File
    onProgress: (progress: number | undefined) => void
}

type UploadWellProps = Omit<DraggerProps, 'action' | 'customRequest'> & {
    onUpload: (upload: UploadedFileRequest) => Promise<void>
    indeterminate?: boolean
    errorText?: string
    instructionsText?: string
}

const useUploadWellTranslation = createUseTranslation({
    EN: {
        error: `An error occurred whilst processing the file`,
        instructions: `Click here or drag and drop a file`,
    },
    FR: {
        error: `Une erreur s'est produite lors du chargement du fichier`,
        instructions: `Cliquez ici ou glissez-déposez un fichier`,
    },
})

const defaultProps: Partial<DraggerProps> = {
    showUploadList: false,
}

export const UploadWell: FC<UploadWellProps> = ({
    onUpload,
    errorText,
    instructionsText,
    indeterminate = true,
    ...props
}) => {
    const styles = useUploadWellStyles()

    const [error, setError] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [progress, setProgress] = useState<number | undefined>(undefined)

    const { t } = useUploadWellTranslation()

    const onProgress = useCallback((progress: number | undefined) => {
        setProgress(progress)
    }, [])

    const process = useAsyncCallback(
        async ({ file }: UploadRequestOption) => {
            setError(false)
            setIsProcessing(true)
            setProgress(undefined)

            try {
                if (!isFile(file)) {
                    throw new Error('Unknown file object')
                }
                await onUpload({
                    file,
                    onProgress,
                })
            } catch (error) {
                logger.error('Error handling uploaded file', { error })
                setError(true)
            } finally {
                setIsProcessing(false)
                setProgress(undefined)
            }
        },
        [onProgress, onUpload]
    )

    const resolvedProps: DraggerProps = {
        ...defaultProps,
        ...props,
        disabled: props.disabled || isProcessing,
    }

    const icon = useMemo(() => {
        if (error) {
            return <FileExclamationOutlined />
        }

        return <UploadOutlined />
    }, [error])

    return (
        <Upload.Dragger customRequest={process} {...resolvedProps}>
            {error ? (
                <>
                    <p className={styles.icon}>{icon}</p>
                    <p className={styles.instructions}>{errorText ?? t('error')}</p>
                </>
            ) : isProcessing ? (
                <div className={styles.processing}>
                    {indeterminate ? (
                        <LoadingOutlined />
                    ) : (
                        <Progress
                            type="circle"
                            percent={progress}
                            width={80}
                            strokeColor={colors.primary}
                            format={uploadProgressFormat}
                        />
                    )}
                </div>
            ) : (
                <>
                    <p className={styles.icon}>{icon}</p>
                    <p className={styles.instructions}>{instructionsText ?? t('instructions')}</p>
                </>
            )}
        </Upload.Dragger>
    )
}

const uploadProgressFormat = (percent?: number | undefined) => {
    if (percent === 100) {
        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
        return <CheckOutlined style={{ color: colors.primary }} />
    }
    return `${percent}%`
}
