import { Breadcrumb, Col, Divider, Row } from 'antd'
import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

import { OperationStatus } from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import { OperationStatusTag } from '../OperationStatusTag'

type Operation = {
    id: string
    name: string
    status: OperationStatus
    fromAddress?: string | null | undefined
}

type Participant = {
    info: {
        name: string
    }
}

type OperationHeaderProps = {
    operation?: Operation
    participant?: Participant
    operationsIndex?: boolean
}

const useStyles = createUseStyles({
    headerRight: {
        textAlign: 'right',
    },
    header: {
        fontSize: '1em',
    },
    subHeader: {
        fontSize: '0.8em',
        marginTop: 5,
        color: colors.grey7,
    },
})

const useOperationHeaderTranslation = createUseTranslation({
    EN: {
        operations: 'Operations',
    },
    FR: {
        operations: 'Opérations',
    },
})

export const OperationHeader: FC<OperationHeaderProps> = ({
    operation,
    participant,
    children,
    operationsIndex = true,
}) => {
    const styles = useStyles()
    const { t } = useOperationHeaderTranslation()

    const breadcrumbs: BreadcrumbItemType[] = useMemo(() => {
        const items: BreadcrumbItemType[] = []

        if (operationsIndex) {
            items.push({
                title: <Link to="/operations">{t('operations')}</Link>,
            })
        }

        if (operation !== undefined) {
            items.push({
                title: (
                    <>
                        {operation.name}
                        <Divider type="vertical" />
                        {participant !== undefined ? (
                            <>
                                {participant.info.name}
                                <Divider type="vertical" />
                            </>
                        ) : null}
                        <OperationStatusTag status={operation.status} />
                    </>
                ),
            })
        }

        return items
    }, [operation, operationsIndex, participant, t])

    return (
        <Row>
            <Col flex={4}>
                <Breadcrumb className={styles.header} items={breadcrumbs} separator=">" />
                <div className={styles.subHeader}>{operation?.fromAddress}</div>
            </Col>
            <Col flex="auto" className={styles.headerRight}>
                {children}
            </Col>
        </Row>
    )
}
