import { CheckCircleTwoTone } from '@ant-design/icons'
import { useMemo } from 'react'

import { EmailStatus } from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'

import { HasStatus, StatusDisplayInfo } from '../Status'
import { useStatuses } from '../statuses'

export type HasEmailStatus = HasStatus<EmailStatus>

const useEmailStatusTranslation = createUseTranslation({
    FR: {
        tooltip: 'Email envoyé',
        sent_one: '{{count}} envoyé',
        sent_other: '{{count}} envoyés',
    },
    EN: {
        tooltip: 'Email sent',
        sent_one: '{{count}} sent',
        sent_other: '{{count}} sent',
    },
})

export const useEmailStatusDisplayInfo: () => StatusDisplayInfo<EmailStatus> = () => {
    const statuses = useStatuses()
    const { t } = useEmailStatusTranslation()

    return useMemo(
        () => ({
            ...statuses,
            SENT: {
                tooltip: t('tooltip'),
                icon: <CheckCircleTwoTone twoToneColor={colors.success} />,
                message: count => t('sent', { count }),
                color: 'green',
            },
        }),
        [statuses, t]
    )
}
