import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import logo from './assets/logo.png'

const useStyles = createUseStyles({
    loginFrame: {
        minHeight: '100%',
        width: '100%',
        backgroundColor: colors.blue1,
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
    },
    loginFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '35%',
    },
    loginForm: {
        border: '1px solid',
        borderColor: colors.primary,
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: 25,
        marginBottom: 150,
    },
    loginLogo: {
        backgroundImage: `url('${logo}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        height: '120px',
    },
    loginDivider: {
        borderBottomColor: colors.primary,
        borderBottom: '1px solid',
    },
    loginFormContent: {
        textAlign: 'center',
    },
})

export const LoginFrame: FC = ({ children }) => {
    const styles = useStyles()

    return (
        <div className={styles.loginFrame}>
            <div className={styles.loginFormContainer}>
                <div className={styles.loginForm}>
                    <div className={styles.loginLogo} />
                    <div className={styles.loginDivider} />
                    <div className={styles.loginFormContent}>{children}</div>
                </div>
            </div>
        </div>
    )
}
