import { Card } from 'antd'
import isNil from 'lodash/isNil'
import { useMemo } from 'react'

import { operationIsOpen } from '@publica/common'
import { FC } from '@publica/ui-common-utils'
import { DocumentTableProps, Spinner } from '@publica/ui-web-components'
import { ArrayItem } from '@publica/utils'

import { DocumentCategory, OperationStatus, useGetDocumentsForParticipantQuery } from '../../../data'
import { Notifications } from '../../components'
import { Notification } from '../../types'
import { useParticipationContext } from '../context'
import { DocumentsIndex } from './DocumentsIndex'

export const Documents: FC = () => {
    const ctx = useParticipationContext()

    const { data, loading } = useGetDocumentsForParticipantQuery({
        variables: { participantId: ctx.participant.id },
        pollInterval: __SLOW_POLLING__,
    })

    const participant = data?.participant

    if (loading || isNil(participant)) {
        return <Spinner />
    }

    const operation = participant?.operation

    return <DocumentsCard operation={operation} participant={participant} />
}

type DocumentsCardProps = {
    operation: {
        id: string
        status: OperationStatus
    }
    participant: {
        id: string
        notifications: Notification[]
        documents: (ArrayItem<DocumentTableProps['documents']> & { categories: DocumentCategory[] })[]
    }
}

const DocumentsCard: FC<DocumentsCardProps> = ({ operation, participant }) => {
    const documents = participant.documents
    const closed = !operationIsOpen(operation)

    const notifications = useMemo(
        () =>
            participant.notifications.filter(
                notification => !closed && notification.__typename === 'RequiredSignaturesNotification'
            ),
        [participant.notifications, closed]
    )

    return (
        <Card title="Documents">
            <Notifications notifications={notifications} participant={participant} />
            <DocumentsIndex documents={documents} operationId={operation.id} />
        </Card>
    )
}
