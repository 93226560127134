import { Space } from 'antd'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'
import { DocumentControlsProps, DocumentSetDownloadButton } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'

const useDocumentSetActionsStyles = createUseStyles({
    controls: {
        padding: [15, 15, 0, 0],
    },
})

export const DocumentControls: FC<DocumentControlsProps> = ({ selectedDocuments, operationId }) => {
    const styles = utils.useControlsStyles()
    const actionsStyles = useDocumentSetActionsStyles()

    return (
        <div className={[styles.controls, actionsStyles.controls].join(' ')}>
            <Space>
                <DocumentSetDownloadButton documents={selectedDocuments} operationId={operationId} />
            </Space>
        </div>
    )
}
