import { Col, Layout, Row, Space } from 'antd'
import { ReactNode, useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'

import { useAuthState } from '@publica/ui-common-auth'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors, logos } from '@publica/ui-common-styles'
import { FC, useConfig } from '@publica/ui-common-utils'
import { globalToken, sizes } from '@publica/ui-web-styles'

import { icons } from '..'
import { ActionButton } from '../ActionButton'

const useHeaderStyles = createUseStyles({
    headerControls: {
        textAlign: 'right',
    },
    header: {
        height: 'auto !important',
        '& *::selection': {
            background: 'initial',
        },
    },
    headerFont: {
        fontWeight: 200,
        fontSize: globalToken.fontSize * 2.5,
    },
    menuItemHeader: {
        fontSize: Math.ceil(globalToken.fontSize * 1.3),
    },
    logo: {
        height: sizes.layoutHeaderHeight,
        width: sizes.layoutHeaderHeight,
        backgroundImage: `url('${logos.smallLogo}')`,
        backgroundSize: '55%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
})

const useHeaderTranslation = createUseTranslation({
    FR: {
        logout: 'Déconnexion',
        account: 'Gérer votre compte',
    },
    EN: {
        logout: 'Log out',
        account: 'Manage your account',
    },
})

type HeaderProps = {
    logo?: ReactNode
}

const DefaultLogo: FC = () => {
    const styles = useHeaderStyles()

    return (
        <>
            <Col flex="none">
                <div className={styles.logo}></div>
            </Col>
            <Col flex="none" className={styles.headerFont}>
                C-Level Partners
            </Col>
        </>
    )
}

export const Header: FC<HeaderProps> = ({ logo }) => {
    const authState = useAuthState(true)
    const loggedIn = authState?.isAuthenticated ?? false
    const { t } = useHeaderTranslation()
    const styles = useHeaderStyles()
    logo = logo ?? <DefaultLogo />

    const navigate = useNavigate()

    const logout = useCallback(() => {
        if (authState?.isAuthenticated) {
            void authState.state.logout()
        }
    }, [authState])

    const manageAccount = useCallback(() => {
        if (authState?.isAuthenticated) {
            navigate('/account')
        }
    }, [authState?.isAuthenticated, navigate])

    return (
        <Layout.Header className={styles.header}>
            <Row>
                <Col span={6} offset={9}>
                    <EnvironmentBanner />
                </Col>
            </Row>
            <Row>
                {logo}
                <Col flex="auto" className={styles.headerControls}>
                    {loggedIn ? (
                        <Space>
                            <ActionButton icon={icons.Account} size="middle" onClick={manageAccount}>
                                {t('account')}
                            </ActionButton>
                            <ActionButton icon={icons.Logout} size="middle" onClick={logout}>
                                {t('logout')}
                            </ActionButton>
                        </Space>
                    ) : null}
                </Col>
            </Row>
        </Layout.Header>
    )
}

const EnvironmentBanner: FC = () => {
    const config = useConfig()

    if (config.environment === 'prod') {
        return null
    }

    return <NonProductionEnvironmentBanner environment={config.environment} />
}

type NonProductionEnvironmentBannerProps = {
    environment: 'dev'
}

const useEnvironmentBannerTranslation = createUseTranslation({
    EN: {
        dev: 'Development',
        devLong: 'Development Environment',
    },
    FR: {
        dev: 'Dévéloppement',
        devLong: 'Environnement de Dévéloppement',
    },
})

const useBannerStyles = createUseStyles({
    banner: {
        backgroundColor: colors.primary,
        fontSize: '12px',
        height: '32px',
        padding: [5, 5, 0, 5],
        lineHeight: 'normal',
        borderRadius: [0, 0, 5, 5],
        textAlign: 'center',
    },
})

const NonProductionEnvironmentBanner: FC<NonProductionEnvironmentBannerProps> = ({ environment }) => {
    const { t } = useEnvironmentBannerTranslation()
    const styles = useBannerStyles()

    useEffect(() => {
        document.title = `Publica - ${t(environment)}`
    }, [environment, t])

    const envLong: 'devLong' = `${environment}Long`

    return <div className={styles.banner}>{t(envLong)}</div>
}
