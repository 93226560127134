import { createUseTranslation } from '@publica/ui-common-i18n'

export const useTermsTranslations = createUseTranslation({
    FR: {
        terms: 'Politique données personnelles',
    },
    EN: {
        terms: 'Privacy Policy',
    },
})
