import { ComponentType, useEffect, useRef } from 'react'
import {
    ErrorBoundary as BaseErrorBoundary,
    ErrorBoundaryPropsWithComponent as BaseErrorBoundaryProps,
} from 'react-error-boundary'

import { logger } from '@publica/ui-common-logger'
import { FC } from '@publica/ui-common-utils'

export type FallbackProps = {
    error: unknown
}

type OriginalFallbackComponent = BaseErrorBoundaryProps['FallbackComponent']

export type ErrorBoundaryProps = Omit<BaseErrorBoundaryProps, 'onError' | 'FallbackComponent'> & {
    registerGlobalHandlers?: boolean
    onError?: (error: unknown, info?: { componentStack?: string | null }) => void
    FallbackComponent: ComponentType<React.PropsWithChildren<FallbackProps>>
}

export const ErrorBoundary: FC<React.PropsWithChildren<ErrorBoundaryProps>> = ({
    registerGlobalHandlers = false,
    FallbackComponent,
    onError,
    ...props
}) => {
    const errorBoundaryRef = useRef<null | BaseErrorBoundary>(null)

    useEffect(() => {
        const setError = (error: unknown) => {
            if (errorBoundaryRef.current !== null) {
                errorBoundaryRef.current.setState({
                    // we 'as any' this as the underlying types expect
                    // everything to be an error - unfortunately, that's not
                    // always the case
                    // The underlying component doesn't actually do anything with the error
                    // except pass it out via the onError and FallbackComponent handlers
                    // whose types we override
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    error: error as any,
                })

                if (onError !== undefined) {
                    onError(error)
                }
            }
        }

        const unhandledRejectionHandler = (ev: PromiseRejectionEvent) => {
            logger.error('Unhandled promise rejection')
            setError(ev.reason)
        }

        const errorHandler = (ev: ErrorEvent) => {
            const { message, filename, lineno, colno, error } = ev

            if (onError === undefined) {
                logger.error(`Unhandled error: ${message}, filename=${filename}, lineno=${lineno}, colno=${colno}`, {
                    labels: {
                        filename,
                        lineno,
                        colno,
                    },
                })
            }

            setError(error)
        }

        if (registerGlobalHandlers) {
            window.addEventListener('unhandledrejection', unhandledRejectionHandler)
            window.addEventListener('error', errorHandler)

            return () => {
                window.removeEventListener('unhandledrejection', unhandledRejectionHandler)
                window.removeEventListener('error', errorHandler)
            }
        }

        return
    }, [errorBoundaryRef, onError, registerGlobalHandlers])

    return (
        <BaseErrorBoundary
            FallbackComponent={FallbackComponent as OriginalFallbackComponent}
            ref={errorBoundaryRef}
            {...props}
            onError={onError}
        />
    )
}
