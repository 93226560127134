import { ThemeConfig, theme } from 'antd'

import { colors } from '@publica/ui-common-styles'

import * as sizes from './sizes'

export const loadTheme = (): ThemeConfig => ({
    token: {
        colorPrimary: colors.primary,
        colorLink: '#000',
        fontFamily: 'Poppins, sans-serif',
    },
    components: {
        Layout: {
            headerBg: colors.blue1,
            headerColor: '#FFF',
            headerHeight: sizes.layoutHeaderHeight,
        },
    },
})

export const globalToken = theme.getDesignToken(loadTheme())
