import isError from 'lodash/isError'

export class RenderableError extends Error {
    public readonly isRenderable = true

    constructor(
        public readonly title: string,
        message: string,
        public readonly instructions?: string
    ) {
        super(message)
        Object.setPrototypeOf(this, RenderableError.prototype)
    }
}

export class NetworkError extends RenderableError {
    constructor(message: string) {
        super('Network Error', message)
    }
}

const isRenderableError = (e: unknown): e is RenderableError =>
    isError(e) && (e as RenderableError).isRenderable === true

type ErrorMessage = {
    title: string
    message: string
    instructions?: string
}

export const getErrorMessage = (e: unknown): ErrorMessage => {
    if (!isRenderableError(e)) {
        return {
            title: 'Erreur',
            message: "Une erreur inconnue s'est produite",
        }
    }

    return {
        title: e.title,
        message: e.message,
        instructions: e.instructions,
    }
}
