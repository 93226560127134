import { Button, Layout, Menu } from 'antd'
import { ItemType } from 'antd/lib/menu/interface'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Route, Routes, useMatch, useNavigate, useParams, useResolvedPath } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { NotFound, Spinner } from '@publica/ui-web-components'
import { assert } from '@publica/utils'

import { useGetOperationForParticipantQuery } from '../../../data'
import { Attachments } from '../attachments'
import { ParticipationContext, useParticipationContext } from '../context'
import { Documents } from '../documents'
import { Profile } from '../profile'

const useParticipationStyles = createUseStyles({
    menu: {
        backgroundColor: 'transparent',
    },
    content: {
        marginLeft: 20,
    },
})

export const Participation: FC = () => {
    const styles = useParticipationStyles()

    const participantId = useParticipantId()

    const { data, loading } = useGetOperationForParticipantQuery({
        variables: {
            participantId,
        },
        pollInterval: __SLOW_POLLING__,
    })

    const participant = data?.participant

    if (isNil(participant)) {
        return loading ? <Spinner /> : <NotFound />
    }

    const operation = participant.operation

    // FIXME(participant-ui): redesign operation page
    return (
        <ParticipationContext participant={participant} operation={operation}>
            <Layout>
                <Layout.Sider
                    width={250}
                    theme="light"
                    className={[styles.menu, 'ant-card', 'ant-card-bordered'].join(' ')}
                >
                    <ParticipationMenu />
                </Layout.Sider>
                <Layout.Content className={styles.content}>
                    <ParticipationRouter />
                </Layout.Content>
            </Layout>
        </ParticipationContext>
    )
}

const useOperationMenuStyles = createUseStyles({
    menu: {
        // TODO(manager-ui): this is hardcoded to be the same as the Card component
        // border radius
        borderRadius: 2,
    },
    back: {
        textAlign: 'center',
        padding: [30, 0],
        backgroundColor: '#FFF',
    },
})

const useParticipationMenuTranslation = createUseTranslation({
    FR: {
        personalInformation: `Fiche d'informations`,
        attachments: 'Documents KYC',
        documents: 'Documents',
    },
    EN: {
        personalInformation: 'Personal information',
        attachments: 'KYC Documents',
        documents: 'Documents',
    },
})

const ParticipationMenu: FC = () => {
    const { operation, participant } = useParticipationContext()
    const styles = useOperationMenuStyles()
    const { t } = useParticipationMenuTranslation()

    const items: ItemType[] = useMemo(
        () => [
            {
                label: operation.name,
                key: participant.id,
                children: [
                    {
                        key: profileKey(participant.id),
                        label: t('personalInformation'),
                    },
                    {
                        key: attachmentsKey(participant.id),
                        label: t('attachments'),
                    },
                    {
                        key: documentsKey(participant.id),
                        label: t('documents'),
                    },
                ],
            },
        ],
        [operation, t, participant.id]
    )

    const selectedKeys = useActiveMenu()
    const openKeys = selectedKeys

    const navigate = useNavigate()

    const onClick = useCallback(
        ({ key }: { key: string }) => {
            assert.defined(key)
            navigate(key)
        },
        [navigate]
    )

    const back = useCallback(() => {
        navigate('/')
    }, [navigate])

    return (
        <>
            <Menu
                mode="inline"
                onClick={onClick}
                items={items}
                className={styles.menu}
                defaultSelectedKeys={selectedKeys}
                defaultOpenKeys={openKeys}
            />
            <div className={styles.back}>
                <Button ghost onClick={back}>
                    {t('back')}
                </Button>
            </div>
        </>
    )
}

const useIsRoute = (path: string): boolean => {
    const resolved = useResolvedPath(path)

    return (
        useMatch({
            path: resolved.pathname,
            end: false,
        }) !== null
    )
}

const profileKey = (id: string) => `/${id}/profile`
const documentsKey = (id: string) => `/${id}/documents`
const attachmentsKey = (id: string) => `/${id}/attachments`

const useParticipantId = () => {
    const { participantId } = useParams()
    assert.defined(participantId)
    return participantId
}

const useActiveMenu = (): [string, string] | undefined => {
    const { participant } = useParticipationContext()

    const isProfile = useIsRoute('profile')
    const isDocuments = useIsRoute('documents')
    const isAttachments = useIsRoute('attachments')

    if (isProfile) {
        return [participant.id, profileKey(participant.id)]
    } else if (isAttachments) {
        return [participant.id, attachmentsKey(participant.id)]
    } else if (isDocuments) {
        return [participant.id, documentsKey(participant.id)]
    }

    return
}

const profile = <Profile />
const attachments = <Attachments />
const documents = <Documents />

const ParticipationRouter: FC = () => {
    return (
        <Routes>
            <Route path="profile/*" element={profile} />
            <Route path="attachments" element={attachments} />
            <Route path="documents" element={documents} />
        </Routes>
    )
}
