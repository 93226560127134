import { DateTime } from 'luxon'
import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'
import {
    type Currency,
    type DateFormat,
    booleansLookup,
    countryLookup,
    maritalStatusLookup,
    nationalityLookup,
    titlesLookup,
} from '@publica/lookups'

import type { BooleanValue, DateValue, FloatValue, LookupValue, MapValue, TextValue } from './types'
import { type HasKey, mapValueAsRecord } from './utils'

export type RenderInstruction =
    | RenderTextInstruction
    | RenderFloatInstruction
    | RenderBooleanInstruction
    | RenderDateInstruction
    | RenderLookupInstruction
    | RenderMapInstruction

type WithoutKey<T extends HasKey> = Omit<T, 'key'>

export type RenderTextInstruction = WithoutKey<TextValue>

export type RenderFloatInstruction = WithoutKey<FloatValue> & {
    options: {
        locale: KnownLocale
        precision?: number
        numberFormat: 'NUMBER' | 'WORDS'
        currency?: Currency
    }
}

export type RenderBooleanInstruction = WithoutKey<BooleanValue> & {
    options: {
        locale: KnownLocale
    }
}

export type RenderDateInstruction = WithoutKey<DateValue> & {
    options: {
        locale: KnownLocale
        dateFormat: DateFormat
    }
}

export type RenderLookupInstruction = WithoutKey<LookupValue> & {
    options: {
        locale: KnownLocale
    }
}

export type RenderMapInstruction = WithoutKey<MapValue> & {
    options: {
        locale: KnownLocale
        subType: 'ADDRESS'
    }
}

export const renderValue = (instruction: RenderInstruction): string => {
    switch (instruction.type) {
        case 'TextValue':
            return instruction.textValue
        case 'FloatValue':
            return renderFloatValue(instruction)
        case 'BooleanValue':
            return renderBooleanValue(instruction)
        case 'DateValue':
            return renderDateValue(instruction)
        case 'LookupValue':
            return renderLookupValue(instruction)
        case 'MapValue':
            return renderMapValue(instruction)
    }
}

const renderFloatValue = ({ floatValue, options }: RenderFloatInstruction): string => {
    // FIXME(render-float): as words
    const currencyOptions: Intl.NumberFormatOptions = {}

    if (options.currency !== undefined) {
        currencyOptions.style = 'currency'
        currencyOptions.currency = options.currency
        currencyOptions.currencyDisplay = 'narrowSymbol'
    }

    return floatValue.toLocaleString(options.locale, {
        maximumFractionDigits: options.precision,
        minimumFractionDigits: options.precision,
        ...currencyOptions,
    })
}

const renderBooleanValue = ({ booleanValue, options }: RenderBooleanInstruction): string =>
    booleansLookup.labelForKeyAndLocale(booleanValue ? 'TRUE' : 'FALSE', options.locale)

const renderDateValue = ({ dateValue, options }: RenderDateInstruction): string => {
    const rezoned = dateValue.setZone('utc').setLocale(options.locale)

    if (options.dateFormat === 'CONDENSED') {
        return rezoned.toLocaleString()
    }
    return rezoned.toLocaleString(DateTime.DATE_HUGE)
}

const renderLookupValue = ({ lookupValue, options }: RenderLookupInstruction): string => {
    switch (lookupValue.dictionary) {
        case 'COUNTRY':
            return countryLookup.labelForKeyAndLocale(lookupValue.key, options.locale)
        case 'NATIONALITY':
            return nationalityLookup.labelForKeyAndLocale(lookupValue.key, options.locale)
        case 'MARITAL_STATUS':
            return maritalStatusLookup.labelForKeyAndLocale(lookupValue.key, options.locale)
        case 'TITLE':
            return titlesLookup.labelForKeyAndLocale(lookupValue.key, options.locale)
    }
}

const renderMapValue = (instruction: RenderMapInstruction): string => {
    switch (instruction.options.subType) {
        case 'ADDRESS':
            return renderAddressMapValue(instruction)
    }
}

const renderAddressMapValue = ({ mapValue, options }: RenderMapInstruction): string => {
    const record = mapValueAsRecord(mapValue)

    const parts: string[] = []
    const { address, city, postCode, country } = addressMapValueRecordSchema.parse(record)

    const components = options.locale === 'EN' ? [address, city, postCode] : [address, postCode, city]

    for (const part of components) {
        if (part !== undefined) {
            parts.push(part)
        }
    }

    if (country !== undefined) {
        const countryLabel = countryLookup.labelForKeyAndLocale(country, options.locale)
        parts.push(countryLabel)
    }

    return parts.join(', ')
}

export const addressMapValueRecordSchema = z.object({
    address: z.string().optional(),
    city: z.string().optional(),
    postCode: z.string().optional(),
    country: z.enum(countryLookup.keys()).optional(),
})

export type AddressMapValue = z.infer<typeof addressMapValueRecordSchema>
