import { CheckCircleTwoTone } from '@ant-design/icons'
import { useMemo } from 'react'

import { DocumentStatus } from '@publica/api-graphql'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'

import { HasStatus, StatusDisplayInfo } from '../Status'
import { useStatuses } from '../statuses'

export type HasDocumentStatus = HasStatus<DocumentStatus>

const useDocumentStatusDisplayInfoTranslation = createUseTranslation({
    FR: {
        published: 'Document publié',
        error: `Une erreur s'est produite lors de la génération`,
        publishedDetail_one: `{{count}} publié`,
        publishedDetail_other: `{{count}} publiés`,
    },
    EN: {
        published: 'Document published',
        error: `An error ocurred during the document generation`,
        publishedDetail_one: `{{count}} published`,
        publishedDetail_other: `{{count}} published`,
    },
})

export const useDocumentStatusDisplayInfo: () => StatusDisplayInfo<DocumentStatus> = () => {
    const { t } = useDocumentStatusDisplayInfoTranslation()
    const statuses = useStatuses()

    return useMemo(
        () => ({
            ...statuses,
            PUBLISHED: {
                tooltip: t('published'),
                icon: <CheckCircleTwoTone twoToneColor={colors.success} />,
                message: count => t('publishedDetail', { count }),
                color: 'green',
            },
            ERROR: {
                ...statuses.ERROR,
                tooltip: t('error'),
            },
        }),
        [statuses, t]
    )
}
