import { InMemoryCache } from '@apollo/client'
import { Card, Col, ConfigProvider, Result, Row, notification } from 'antd'
import { Locale } from 'antd/lib/locale'
import enUS from 'antd/lib/locale/en_US'
import frFR from 'antd/lib/locale/fr_FR'
import { ReactElement, StrictMode, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { createUseStyles } from 'react-jss'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import { AuthGuard, AuthGuardProps, AuthStateBuilder, AuthStateProvider } from '@publica/ui-common-auth'
import { ErrorBoundary, ErrorBoundaryProps } from '@publica/ui-common-components'
import { createUseTranslation, localeChangeEvents } from '@publica/ui-common-i18n'
import { logger } from '@publica/ui-common-logger'
import { ApolloProvider } from '@publica/ui-common-network'
import '@publica/ui-common-trace'
import { FC } from '@publica/ui-common-utils'
import { useVersion } from '@publica/ui-common-utils'
import { ActionButton, Layout, LoginFrame, PageLoad } from '@publica/ui-web-components'
import { loadTheme } from '@publica/ui-web-styles'
import { lang } from '@publica/utils'

import { init } from './init'

init()

type BootOptions = {
    auth: {
        stateBuilder: AuthStateBuilder
        login: ReactElement
        check?: AuthGuardProps['check']
    }
    apollo?: {
        cache?: InMemoryCache
    }
}

const logError: NonNullable<ErrorBoundaryProps['onError']> = (error, info) => {
    logger.error('Error', { error, payload: info ?? {} })
}

const useStyles = createUseStyles({
    error: {
        marginTop: 100,
    },
})

const useErrorTranslation = createUseTranslation({
    FR: {
        'error.title': `Une erreur s'est produite`,
        'error.subtitle': `Veuillez recharger la page`,
    },
    EN: {
        'error.title': 'An error has occurred',
        'error.subtitle': 'Please reload the page',
    },
})

const ErrorComponent: FC = () => {
    const styles = useStyles()
    const { t } = useErrorTranslation()

    return (
        <Layout>
            <Row className={styles.error}>
                <Col offset={6} span={12}>
                    <Card>
                        <Result status="error" title={t('error.title')} subTitle={t('error.subtitle')} />
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

const pageLoad = <PageLoad />

export const boot = (App: React.JSX.Element, options: BootOptions) => {
    logger.logBanner()

    const { auth } = options

    // eslint-disable-next-line react-perf/jsx-no-jsx-as-prop
    const login = <LoginFrame>{auth.login}</LoginFrame>

    const node = document.getElementById('root')
    const root = createRoot(node!)

    root.render(
        <StrictMode>
            <Config>
                <BrowserRouter>
                    <RecoilRoot>
                        <ErrorBoundary registerGlobalHandlers FallbackComponent={ErrorComponent} onError={logError}>
                            <Suspense fallback={pageLoad}>
                                <AuthStateProvider builder={auth.stateBuilder}>
                                    <AuthGuard login={login} initializing={pageLoad} check={auth.check}>
                                        <ApolloProvider cache={options.apollo?.cache}>
                                            <DndProvider backend={HTML5Backend}>{App}</DndProvider>
                                        </ApolloProvider>
                                    </AuthGuard>
                                </AuthStateProvider>
                                <VersionChecker />
                            </Suspense>
                        </ErrorBoundary>
                    </RecoilRoot>
                </BrowserRouter>
            </Config>
        </StrictMode>
    )
}

const Config: FC = ({ children }) => {
    const frLocale = useMemo<Locale>(
        () => ({
            ...frFR,
            Form: {
                optional: '(Facultatif)',
                defaultValidateMessages: {},
            },
        }),
        []
    )

    const enLocale = useMemo<Locale>(
        () => ({
            ...enUS,
            Form: {
                optional: '(Optional)',
                defaultValidateMessages: {},
            },
        }),
        []
    )

    const [currentLocale, setCurrentLocale] = useState<Locale>(frLocale)

    useEffect(() => {
        localeChangeEvents.subscribe(({ locale }) => {
            switch (locale) {
                case 'IT':
                    logger.warn('IT currently not handled, defaulting to FR')
                // eslint-disable-next-line no-fallthrough
                case 'FR':
                    setCurrentLocale(frLocale)
                    break
                case 'EN':
                    setCurrentLocale(enLocale)
                    break
                default:
                    throw lang.NotExhaustedError(locale)
            }
        })
    }, [enLocale, frLocale])

    const form = {
        requiredMark: 'optional',
    } as const

    const theme = useMemo(() => loadTheme(), [])

    useEffect(() => {
        const bgColor = theme.token?.colorPrimary

        if (bgColor !== undefined) {
            document.body.style.backgroundColor = bgColor
        }
    }, [theme.token?.colorPrimary])

    return (
        <ConfigProvider locale={currentLocale} form={form} theme={theme}>
            {children}
        </ConfigProvider>
    )
}

const useVersionCheckerTranslation = createUseTranslation({
    EN: {
        newVersionTitle: 'New Version',
        newVersionDescription: 'A new version of the app is available, please reload when convenient.',
        reload: 'Reload',
    },
    FR: {
        newVersionTitle: 'Nouvelle Version',
        newVersionDescription: `Une nouvelle version de l'application est disponible. Veuillez recharger la page.`,
        reload: 'Recharger',
    },
})

const VersionChecker: FC = () => {
    const version = useVersion()
    const { t } = useVersionCheckerTranslation()
    const [notificationApi, context] = notification.useNotification()

    const onClick = useCallback(() => {
        location.reload()
    }, [])
    const reload = useMemo(() => <ActionButton onClick={onClick}>{t('reload')}</ActionButton>, [onClick, t])

    useEffect(() => {
        if (version.newVersion) {
            notificationApi.info({
                key: 'newVersion',
                message: t('newVersionTitle'),
                description: t('newVersionDescription'),
                placement: 'bottomRight',
                duration: 0,
                btn: reload,
            })
        }
    }, [t, version.newVersion, reload, notificationApi])

    return context
}
