import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'

export const useFormStyles = createUseStyles({
    formLabelNote: {
        paddingBottom: 15,
        fontSize: '0.9em',
        color: colors.grey7,
    },
    formSideLabelNote: {
        extend: 'formLabelNote',
        paddingLeft: 15,
    },
    nestedFormItem: {
        marginBottom: 0,
    },
})

export const useControlsStyles = createUseStyles({
    controls: {
        textAlign: 'right',
        marginBottom: 15,
    },
    footerControls: {
        textAlign: 'right',
        marginTop: 15,
        marginBottom: 0,
    },
})

export const useActionsStyles = createUseStyles({
    actions: {
        fontSize: '0.9em',
    },
})

export const useRowStyles = createUseStyles({
    deactivatedRow: {
        color: colors.grey6,
    },
})

export const useConfirmationStyles = createUseStyles({
    confirmation: {
        '& .ant-popover-message-title': {
            paddingLeft: 0,
            textAlign: 'center',
        },
        '& .ant-popover-message-title p': {
            margin: 0,
        },
        '& .ant-popover-buttons': {
            textAlign: 'center',
        },
    },
    confirmationAlert: {
        margin: [10, 0],
    },
})
