import { Divider, Input } from 'antd'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { countryLookup } from '@publica/lookups'
import { AddressMapValue } from '@publica/render'
import { createUseTranslation, useLocalizedStringResolver } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import { LookupSelect } from '../../../'
import { Field, MapFieldParameters } from '../../types'
import { FormItem } from './FormItem'
import { useCommonRules } from './rules'

type AddressValueFormItemProps = {
    field: Field<MapFieldParameters>
}

type AddressPath = [string, keyof AddressMapValue]

const useAddressTranslations = createUseTranslation({
    FR: {
        address: 'Adresse',
        city: 'Ville',
        postCode: 'Code Postal',
        country: 'Pays',
    },
    EN: {
        address: 'Address',
        city: 'City',
        postCode: 'Post Code',
        country: 'Country',
    },
})

export const AddressValueFormItem: FC<AddressValueFormItemProps> = ({ field }) => {
    const addressValueRules = useCommonRules()
    const resolveLocalizedString = useLocalizedStringResolver()
    const { t } = useAddressTranslations()

    const addressPath = useMemo<AddressPath>(() => [field.key, 'address'], [field])
    const cityPath = useMemo<AddressPath>(() => [field.key, 'city'], [field])
    const postCodePath = useMemo<AddressPath>(() => [field.key, 'postCode'], [field])
    const countryPath = useMemo<AddressPath>(() => [field.key, 'country'], [field])

    const addressLabel = useMemo(() => <AddressValueFormItemLabel label={t('address')} />, [t])
    const cityLabel = useMemo(() => <AddressValueFormItemLabel label={t('city')} />, [t])
    const postCodeLabel = useMemo(() => <AddressValueFormItemLabel label={t('postCode')} />, [t])
    const countryLabel = useMemo(() => <AddressValueFormItemLabel label={t('country')} />, [t])

    return (
        <>
            <Divider />
            <h4>{resolveLocalizedString(field.name)}</h4>
            <FormItem label={addressLabel} field={field} name={addressPath} rules={addressValueRules}>
                <Input />
            </FormItem>
            <FormItem label={cityLabel} field={field} name={cityPath} rules={addressValueRules}>
                <Input />
            </FormItem>
            <FormItem label={postCodeLabel} field={field} name={postCodePath} rules={addressValueRules}>
                <Input />
            </FormItem>
            <FormItem label={countryLabel} field={field} name={countryPath} rules={addressValueRules}>
                <LookupSelect lookup={countryLookup} />
            </FormItem>
            <Divider />
        </>
    )
}

const useStyles = createUseStyles({
    label: {
        color: colors.grey7,
    },
})

const AddressValueFormItemLabel: FC<{ label: string }> = ({ label }) => {
    const styles = useStyles()
    return <span className={styles.label}>{label}</span>
}
