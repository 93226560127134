import { z } from 'zod'

import { numberSchema, set } from '@publica/utils'

import { idGenerator } from './idGenerator'
import { fieldInstanceStateSchema, hoistAttributes } from './rawFieldInstance'
import { type ValueFieldInstance, valueFieldInstanceSchema } from './valueFieldInstance'

export const signatureNonTextFieldInstanceKeys = ['SIGNATURE', 'DATE', 'INITIALS'] as const
export const signatureFieldInstanceKeys = [...signatureNonTextFieldInstanceKeys, 'TEXT'] as const

export type SignatureNonTextFieldInstanceKey = (typeof signatureNonTextFieldInstanceKeys)[number]
export type SignatureFieldInstanceKey = (typeof signatureFieldInstanceKeys)[number]

const signatureFieldInstanceKindSchema = z.literal('SIGNATURE')
const signatureNonTextFieldInstanceKeySchema = z.enum(signatureNonTextFieldInstanceKeys)

const unhoistedSignatureNonTextFieldInstanceSchema = z
    .object({
        key: signatureNonTextFieldInstanceKeySchema,
        params: z
            .object({
                kind: signatureFieldInstanceKindSchema,
                state: fieldInstanceStateSchema,
                signatoryIndex: numberSchema,
            })
            .strict(),
    })
    .strict()
    .transform(hoistAttributes)

// This is extracted to fix a coverage issue
const zero = () => 0

const unhoistedSignatureTextFieldInstanceSchema = z.union([
    z
        .object({
            key: z.literal('TEXT'),
            params: z
                .object({
                    /* istanbul ignore next */
                    id: z.string().default(() => idGenerator.getId()),
                    kind: signatureFieldInstanceKindSchema,
                    state: fieldInstanceStateSchema,
                    signatoryIndex: z.union([z.literal('0').transform(zero), z.literal(0)]),
                    prefill: valueFieldInstanceSchema.optional(),
                })
                .strict(),
        })
        .strict()
        .transform(hoistAttributes),
    z
        .object({
            key: z.literal('TEXT'),
            params: z
                .object({
                    id: z.string(),
                    kind: signatureFieldInstanceKindSchema,
                    state: fieldInstanceStateSchema,
                    signatoryIndex: numberSchema,
                })
                .strict(),
        })
        .strict()
        .transform(hoistAttributes),
])

const unhoistedSignatureFieldInstanceSchema = z.union([
    unhoistedSignatureTextFieldInstanceSchema,
    unhoistedSignatureNonTextFieldInstanceSchema,
])

const hoistedSignatureNonTextFieldInstanceSchema = z
    .object({
        key: signatureNonTextFieldInstanceKeySchema,
        kind: signatureFieldInstanceKindSchema,
        state: fieldInstanceStateSchema,
        params: z
            .object({
                signatoryIndex: numberSchema,
            })
            .strict(),
    })
    .strict()

const hoistedSignatureTextFieldInstanceSchema = z.union([
    z
        .object({
            id: z.string(),
            key: z.literal('TEXT'),
            kind: signatureFieldInstanceKindSchema,
            state: fieldInstanceStateSchema,
            params: z
                .object({
                    signatoryIndex: z.union([z.literal('0').transform(zero), z.literal(0)]),
                    prefill: valueFieldInstanceSchema.optional(),
                })
                .strict(),
        })
        .strict(),
    z
        .object({
            id: z.string(),
            key: z.literal('TEXT'),
            kind: signatureFieldInstanceKindSchema,
            state: fieldInstanceStateSchema,
            params: z
                .object({
                    signatoryIndex: numberSchema,
                })
                .strict(),
        })
        .strict(),
])

export type SignatureTextFieldInstance = z.infer<typeof hoistedSignatureTextFieldInstanceSchema>

const hoistedSignatureFieldInstanceSchema = z.union([
    hoistedSignatureNonTextFieldInstanceSchema,
    hoistedSignatureTextFieldInstanceSchema,
])

export const signatureFieldInstanceSchema = z.union([
    unhoistedSignatureFieldInstanceSchema,
    hoistedSignatureFieldInstanceSchema,
])

export type SignatureFieldInstance = z.infer<typeof unhoistedSignatureFieldInstanceSchema>

export type SignatureFieldInstanceConsistency = {
    consistent: boolean
    signatoryCount: number
    expectedSignatureIndexes: Set<number>
    foundSignatureIndexes: Set<number>
    missingSignatureIndexes: Set<number>
}

export const checkSignatureFieldInstanceConsistency = (
    signatureFieldInstances: Iterable<SignatureFieldInstance>
): SignatureFieldInstanceConsistency => {
    const signatureFieldInstancesAsArray = Array.from(signatureFieldInstances)

    if (signatureFieldInstancesAsArray.length === 0) {
        return {
            signatoryCount: 0,
            consistent: true,
            expectedSignatureIndexes: new Set(),
            foundSignatureIndexes: new Set(),
            missingSignatureIndexes: new Set(),
        }
    }

    const foundSignatureIndexes = new Set(signatureFieldInstancesAsArray.map(field => field.params.signatoryIndex))
    const maxSignatureIndex = Math.max(...Array.from(foundSignatureIndexes))
    const signatoryCount = maxSignatureIndex + 1
    const expectedSignatureIndexes = new Set([...Array(signatoryCount).keys()])

    const missingSignatureIndexes = set.difference(expectedSignatureIndexes, foundSignatureIndexes)

    return {
        signatoryCount,
        expectedSignatureIndexes,
        foundSignatureIndexes,
        consistent: missingSignatureIndexes.size === 0,
        missingSignatureIndexes,
    }
}

/* istanbul ignore next */
export const createSignatureFieldInstance = (
    key: SignatureFieldInstanceKey,
    signatoryIndex: number
): SignatureFieldInstance => {
    // Most signature fields are shared, i.e. a signature field will always refer
    // to the same signature, but text fields are independant so we give them
    // a random ID
    if (key === 'TEXT') {
        return {
            key,
            kind: 'SIGNATURE',
            state: 'VALID',
            id: idGenerator.getId(),
            params: {
                signatoryIndex,
            },
        }
    }

    return {
        key,
        kind: 'SIGNATURE',
        state: 'VALID',
        params: {
            signatoryIndex,
        },
    }
}

export type SignatureFieldInstanceWithPrefill = Omit<SignatureTextFieldInstance, 'params'> & {
    params: {
        signatoryIndex: 0
        prefill?: ValueFieldInstance
    }
}

/* istanbul ignore next */
export const signatureFieldInstanceIsPrefillable = (
    field: SignatureFieldInstance
): field is SignatureFieldInstanceWithPrefill => {
    return field.key === 'TEXT' && field.params.signatoryIndex === 0
}
