import { DateTime } from 'luxon'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Base64String: { input: string; output: string; }
  DateTime: { input: DateTime; output: DateTime; }
};

export type Account = {
  __typename: 'Account';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  locale: Locale;
  name: Scalars['String']['output'];
  participations: Array<Participant>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<AccountTitle>;
  type: AccountType;
};

export type AccountCreateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  locale: Locale;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title: AccountTitle;
};

export type AccountTitle =
  | 'MR'
  | 'MRS';

export type AccountType =
  | 'ADMIN'
  | 'PARTICIPANT';

export type AccountUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Locale>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<AccountTitle>;
};

export type Attachment = {
  __typename: 'Attachment';
  group: FieldGroup;
  groups: Array<Group>;
  id: Scalars['ID']['output'];
  name: LocalizedString;
  type: AttachmentType;
};

export type AttachmentCreateInput = {
  group: FieldGroup;
  groupKeys: Array<Scalars['String']['input']>;
  name: LocalizedStringInput;
  type: AttachmentType;
};

export type AttachmentType =
  | 'DOCUMENT'
  | 'IMAGE';

export type AttachmentUpdateInput = {
  name: LocalizedStringInput;
};

export type BooleanValue = Value & {
  __typename: 'BooleanValue';
  booleanValue: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type BooleanValueInput = {
  booleanValue: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
};

export type CancellationLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  debitorAccountId: Scalars['ID']['input'];
};

export type CashSubscriptionLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
};

export type CashTransferLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetType: LedgerTransactionPossiblyDismemberedAssetTypeInput;
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
  debitorAccountId: Scalars['ID']['input'];
};

export type Connection = {
  id: Scalars['ID']['output'];
  pageInfo: PageInfo;
};

export type ConversionLedgerTransactionCreateInput = {
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
  sourceAmount: Scalars['Int']['input'];
  sourceAssetTypeId: Scalars['ID']['input'];
  targetAssetTypeId: Scalars['ID']['input'];
};

export type Currency =
  | 'EUR'
  | 'GBP'
  | 'USD';

export type DateFieldParameters = {
  __typename: 'DateFieldParameters';
  range: DateFieldRange;
};

export type DateFieldParametersInput = {
  range: DateFieldRange;
};

export type DateFieldRange =
  | 'ANY'
  | 'ONLY_FUTURE'
  | 'ONLY_PAST';

export type DateValue = Value & {
  __typename: 'DateValue';
  dateValue: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type DateValueInput = {
  dateValue: Scalars['DateTime']['input'];
  key: Scalars['String']['input'];
};

export type Document = {
  __typename: 'Document';
  categories: Array<DocumentCategory>;
  createdAt: Scalars['DateTime']['output'];
  files: Array<DocumentFile>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  participant: Participant;
  requiresSignature: Scalars['Boolean']['output'];
  signatories: Array<Signatory>;
  status: DocumentStatus;
};

export type DocumentCategory =
  | 'BANK'
  | 'DRAFT'
  | 'FINAL'
  | 'INFORMATION';

export type DocumentFile = {
  __typename: 'DocumentFile';
  createdAt: Scalars['DateTime']['output'];
  format: DocumentFileFormat;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: DocumentFileType;
  url: Scalars['String']['output'];
};

export type DocumentFileFormat =
  | 'PDF'
  | 'WORD'
  | 'ZIP';

export type DocumentFileType =
  | 'CERTIFICATE'
  | 'ORIGINAL'
  | 'SIGNED';

export type DocumentSet = {
  __typename: 'DocumentSet';
  createdAt: Scalars['DateTime']['output'];
  documents: Array<Document>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  requiresSignature: Scalars['Boolean']['output'];
  type: DocumentSetType;
};

export type DocumentSetType =
  | 'MANUAL'
  | 'SIGNED_TEMPLATE'
  | 'TEMPLATE';

export type DocumentStatus =
  | 'ARCHIVED'
  | 'AWAITING_SIGNATURE'
  | 'ERROR'
  | 'GENERATING'
  | 'PENDING'
  | 'PUBLISHED'
  | 'READY'
  | 'SIGNED';

export type DonationDismembermentLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetTypeId: Scalars['ID']['input'];
  bareOwnershipAccountId: Scalars['ID']['input'];
  context: TransactionContextInput;
  debitorAccountId: Scalars['ID']['input'];
  usufructAccountId: Scalars['ID']['input'];
};

export type DonationFullOwnershipLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
  debitorAccountId: Scalars['ID']['input'];
};

export type Email = {
  __typename: 'Email';
  bcc: Array<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  cc: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  manuallyModified: Scalars['Boolean']['output'];
  participant: Participant;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status: EmailStatus;
  subject: Scalars['String']['output'];
  to?: Maybe<Scalars['String']['output']>;
};

export type EmailSet = {
  __typename: 'EmailSet';
  bcc: Array<Scalars['String']['output']>;
  cc: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  emails: Array<Email>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  participants: Array<Participant>;
  subject: Scalars['String']['output'];
  templateVersionId: Scalars['ID']['output'];
};

export type EmailSetCreateInput = {
  bcc: Array<Scalars['String']['input']>;
  cc: Array<Scalars['String']['input']>;
  sendImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  subject: Scalars['String']['input'];
  templateVersionId: Scalars['ID']['input'];
};

export type EmailStatus =
  | 'ARCHIVED'
  | 'ERROR'
  | 'GENERATING'
  | 'PENDING'
  | 'READY'
  | 'SENT';

export type EmissionLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
};

export type EquitySubscriptionLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
};

export type EquityTransferLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetType: LedgerTransactionPossiblyDismemberedAssetTypeInput;
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
  debitorAccountId: Scalars['ID']['input'];
};

export type ExternalDocumentSignatoryInfoInput = {
  email: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type Field = {
  __typename: 'Field';
  formSection?: Maybe<FormSection>;
  group: FieldGroup;
  groups: Array<Group>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: LocalizedString;
  parameters: FieldParameters;
  position?: Maybe<Scalars['Int']['output']>;
};

export type FieldCreateInput = {
  formSectionId?: InputMaybe<Scalars['ID']['input']>;
  group: FieldGroup;
  groupKeys: Array<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name: LocalizedStringInput;
  parameters: FieldParametersInput;
};

export type FieldGroup =
  | 'ACCOUNT_HOLDER_INFORMATION'
  | 'BANKING_INFORMATION'
  | 'FINANCIAL_INFORMATION'
  | 'PERSONAL_INFORMATION'
  | 'PLATFORM_INFORMATION';

export type FieldParameters = DateFieldParameters | FloatFieldParameters | LookupFieldParameters | MapFieldParameters | TextFieldParameters;

export type FieldParametersInput =
  { date: DateFieldParametersInput; float?: never; lookup?: never; map?: never; text?: never; }
  |  { date?: never; float: FloatFieldParametersInput; lookup?: never; map?: never; text?: never; }
  |  { date?: never; float?: never; lookup: LookupFieldParametersInput; map?: never; text?: never; }
  |  { date?: never; float?: never; lookup?: never; map: MapFieldParametersInput; text?: never; }
  |  { date?: never; float?: never; lookup?: never; map?: never; text: TextFieldParametersInput; };

export type FieldUpdateInput = {
  formSectionId?: InputMaybe<Scalars['ID']['input']>;
  name: LocalizedStringInput;
  parameters: FieldParametersInput;
};

export type FloatFieldParameters = {
  __typename: 'FloatFieldParameters';
  currency?: Maybe<Currency>;
  precision: Scalars['Int']['output'];
};

export type FloatFieldParametersInput = {
  currency?: InputMaybe<Currency>;
  precision: Scalars['Int']['input'];
};

export type FloatValue = Value & {
  __typename: 'FloatValue';
  floatValue: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type FloatValueInput = {
  floatValue: Scalars['Float']['input'];
  key: Scalars['String']['input'];
};

export type FormSection = {
  __typename: 'FormSection';
  fields: Array<Field>;
  id: Scalars['ID']['output'];
  name: LocalizedString;
  position?: Maybe<Scalars['Int']['output']>;
};

export type FormSectionCreateInput = {
  name: LocalizedStringInput;
};

export type FormSectionUpdateInput = {
  name: LocalizedStringInput;
};

export type Group = {
  __typename: 'Group';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: LocalizedString;
  type: GroupType;
};

export type GroupType =
  | 'SYSTEM'
  | 'USER';

export type HistoricalDismembermentLedgerBalanceCreateInput = {
  amount: Scalars['Float']['input'];
  bareOwnershipLedgerAccountId: Scalars['ID']['input'];
  ledgerAssetTypeId: Scalars['ID']['input'];
  usufructLedgerAccountId: Scalars['ID']['input'];
};

export type HistoricalFullOwnershipLedgerBalanceSetInput = {
  amount: Scalars['Float']['input'];
  ledgerAssetTypeId: Scalars['ID']['input'];
};

export type ILedgerBalance = {
  amount: LedgerBalanceAmount;
  asPercentOfLedgerTotal: LedgerBalanceAmount;
  id: Scalars['ID']['output'];
};

export type Ledger = {
  __typename: 'Ledger';
  account: LedgerAccount;
  assetTypes: Array<LedgerAssetType>;
  balance: LedgerBalance;
  groups: Array<LedgerGroup>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  transactions: Array<LedgerTransaction>;
};


export type LedgerAccountArgs = {
  ledgerAccountId: Scalars['ID']['input'];
};

export type LedgerAccount = {
  __typename: 'LedgerAccount';
  balance: LedgerAccountBalance;
  beneficiary: LedgerBeneficiary;
  id: Scalars['ID']['output'];
  type: LedgerAccountType;
};

export type LedgerAccountBalance = ILedgerBalance & {
  __typename: 'LedgerAccountBalance';
  account: LedgerAccount;
  amount: LedgerBalanceAmount;
  asPercentOfLedgerTotal: LedgerBalanceAmount;
  balanceByAssetType: Array<LedgerAssetTypeBalance>;
  id: Scalars['ID']['output'];
};

export type LedgerAccountType =
  | 'PEA'
  | 'PEA_PME'
  | 'PLEDGE'
  | 'STANDARD';

export type LedgerAccountTypeExcludingPledge =
  | 'PEA'
  | 'PEA_PME'
  | 'STANDARD';

export type LedgerAssetType = {
  __typename: 'LedgerAssetType';
  eligibleAccountTypes: Array<LedgerAccountType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LedgerAssetTypeBalance = ILedgerBalance & {
  __typename: 'LedgerAssetTypeBalance';
  amount: LedgerBalanceAmount;
  asPercentOfLedgerTotal: LedgerBalanceAmount;
  assetType: LedgerAssetType;
  balanceByHoldership: LedgerHoldershipBalances;
  id: Scalars['ID']['output'];
};

export type LedgerAssetTypeDismemberment = {
  holdershipType: LedgerDismembermentHoldershipType;
  transactionId: Scalars['ID']['input'];
};

export type LedgerBalance = ILedgerBalance & {
  __typename: 'LedgerBalance';
  amount: LedgerBalanceAmount;
  asPercentOfLedgerTotal: LedgerBalanceAmount;
  balanceByAssetType: Array<LedgerAssetTypeBalance>;
  id: Scalars['ID']['output'];
};

export type LedgerBalanceAmount = {
  __typename: 'LedgerBalanceAmount';
  committedAmount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  uncommittedAmount: Scalars['Float']['output'];
};

export type LedgerBalanceOrigin = {
  amount: LedgerBalanceAmount;
  historical: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  status: LedgerTransactionStatus;
};

export type LedgerBeneficiary = {
  __typename: 'LedgerBeneficiary';
  accounts: Array<LedgerAccount>;
  balance: LedgerBeneficiaryBalance;
  group: LedgerGroup;
  id: Scalars['ID']['output'];
  legalEntity: LegalEntity;
};

export type LedgerBeneficiaryBalance = ILedgerBalance & {
  __typename: 'LedgerBeneficiaryBalance';
  amount: LedgerBalanceAmount;
  asPercentOfLedgerTotal: LedgerBalanceAmount;
  balanceByAccount: Array<LedgerAccountBalance>;
  balanceByAssetType: Array<LedgerAssetTypeBalance>;
  beneficiary: LedgerBeneficiary;
  id: Scalars['ID']['output'];
};

export type LedgerDismemberedBalanceOrigin = LedgerBalanceOrigin & {
  __typename: 'LedgerDismemberedBalanceOrigin';
  amount: LedgerBalanceAmount;
  dismemberment: LedgerDismemberment;
  historical: Scalars['Boolean']['output'];
  holdershipType: LedgerHoldershipType;
  id: Scalars['ID']['output'];
  status: LedgerTransactionStatus;
};

export type LedgerDismemberment = {
  __typename: 'LedgerDismemberment';
  amount: Scalars['Float']['output'];
  assetType: LedgerAssetType;
  bareOwnershipHolder: LedgerAccount;
  id: Scalars['ID']['output'];
  transaction: LedgerTransaction;
  usufructHolder: LedgerAccount;
};

export type LedgerDismembermentHoldershipType =
  | 'BARE_OWNERSHIP'
  | 'USUFRUCT';

export type LedgerEntry = {
  __typename: 'LedgerEntry';
  account: LedgerAccount;
  amount: Scalars['Float']['output'];
  assetType: LedgerAssetType;
  holdershipType: LedgerHoldershipType;
  id: Scalars['ID']['output'];
  sequence: Scalars['Int']['output'];
};

export type LedgerFullOwnershipBalanceOrigin = LedgerBalanceOrigin & {
  __typename: 'LedgerFullOwnershipBalanceOrigin';
  amount: LedgerBalanceAmount;
  historical: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  status: LedgerTransactionStatus;
};

export type LedgerGroup = {
  __typename: 'LedgerGroup';
  balance: LedgerGroupBalance;
  id: Scalars['ID']['output'];
  members: Array<LedgerBeneficiary>;
  name: Scalars['String']['output'];
};

export type LedgerGroupBalance = ILedgerBalance & {
  __typename: 'LedgerGroupBalance';
  amount: LedgerBalanceAmount;
  asPercentOfLedgerTotal: LedgerBalanceAmount;
  balanceByAssetType: Array<LedgerAssetTypeBalance>;
  balanceByBeneficiary: Array<LedgerBeneficiaryBalance>;
  group: LedgerGroup;
  id: Scalars['ID']['output'];
};

export type LedgerHoldershipBalance = {
  __typename: 'LedgerHoldershipBalance';
  amount: LedgerBalanceAmount;
  id: Scalars['ID']['output'];
  origins: Array<LedgerBalanceOrigin>;
  type: LedgerHoldershipType;
};

export type LedgerHoldershipBalances = {
  __typename: 'LedgerHoldershipBalances';
  bareOwnership: LedgerHoldershipBalance;
  fullOwnership: LedgerHoldershipBalance;
  id: Scalars['ID']['output'];
  usufruct: LedgerHoldershipBalance;
};

export type LedgerHoldershipType =
  | 'BARE_OWNERSHIP'
  | 'FULL_OWNERSHIP'
  | 'USUFRUCT';

export type LedgerTransaction = {
  __typename: 'LedgerTransaction';
  comment?: Maybe<Scalars['String']['output']>;
  committedAt?: Maybe<Scalars['DateTime']['output']>;
  entries: Array<LedgerEntry>;
  id: Scalars['ID']['output'];
  internalSequence: Scalars['Int']['output'];
  occurredAt: Scalars['DateTime']['output'];
  sequence?: Maybe<Scalars['Int']['output']>;
  status: LedgerTransactionStatus;
  type: LedgerTransactionType;
};

export type LedgerTransactionPossiblyDismemberedAssetTypeInput = {
  assetTypeId: Scalars['ID']['input'];
  dismemberment?: InputMaybe<LedgerAssetTypeDismemberment>;
};

export type LedgerTransactionStatus =
  | 'COMMITTED'
  | 'UNCOMMITTED';

export type LedgerTransactionType =
  | 'CANCELLATION'
  | 'CONVERSION'
  | 'DONATION_DISMEMBERMENT'
  | 'DONATION_FULL_OWNERSHIP'
  | 'EMISSION'
  | 'PLEDGE'
  | 'PLEDGE_RELEASE'
  | 'RECONSTITUTION'
  | 'REVERSE_STOCK_SPLIT'
  | 'STOCK_SPLIT'
  | 'SUBSCRIPTION_CASH'
  | 'SUBSCRIPTION_EQUITY'
  | 'TRANSFER_CASH'
  | 'TRANSFER_EQUITY';

export type LegalEntity = {
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  representative: Account;
};

export type LegalEntityCompany = LegalEntity & {
  __typename: 'LegalEntityCompany';
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  registrationNumber: Scalars['String']['output'];
  representative: Account;
};

export type LegalEntityCompanyCreate = {
  name: Scalars['String']['input'];
  registrationNumber: Scalars['String']['input'];
  representativeId: Scalars['ID']['input'];
};

export type LegalEntityIndividual = LegalEntity & {
  __typename: 'LegalEntityIndividual';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  representative: Account;
  title: AccountTitle;
};

export type LegalEntityIndividualCreate = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  representativeId: Scalars['ID']['input'];
  title: AccountTitle;
};

export type LegalEntityType =
  | 'COMPANY'
  | 'INDIVIDUAL';

export type Locale =
  | 'EN'
  | 'FR'
  | 'IT';

export type LocalizedString = {
  __typename: 'LocalizedString';
  EN?: Maybe<Scalars['String']['output']>;
  FR: Scalars['String']['output'];
  IT?: Maybe<Scalars['String']['output']>;
};

export type LocalizedStringInput = {
  EN?: InputMaybe<Scalars['String']['input']>;
  FR: Scalars['String']['input'];
  IT?: InputMaybe<Scalars['String']['input']>;
};

export type LookupFieldParameters = {
  __typename: 'LookupFieldParameters';
  dictionary: LookupValueDictionary;
};

export type LookupFieldParametersInput = {
  dictionary: LookupValueDictionary;
};

export type LookupValue = Value & {
  __typename: 'LookupValue';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  lookupValue: LookupValueEntry;
};

export type LookupValueDictionary =
  | 'COUNTRY'
  | 'MARITAL_STATUS'
  | 'NATIONALITY'
  | 'TITLE';

export type LookupValueEntry = {
  __typename: 'LookupValueEntry';
  dictionary: LookupValueDictionary;
  key: Scalars['String']['output'];
};

export type LookupValueEntryInput = {
  dictionary: LookupValueDictionary;
  key: Scalars['String']['input'];
};

export type LookupValueInput = {
  key: Scalars['String']['input'];
  lookupValue: LookupValueEntryInput;
};

export type MapFieldParameters = {
  __typename: 'MapFieldParameters';
  subType: MapValueType;
};

export type MapFieldParametersInput = {
  subType: MapValueType;
};

export type MapValue = Value & {
  __typename: 'MapValue';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  mapValue: Array<MapValueEntry>;
};

export type MapValueEntry = {
  __typename: 'MapValueEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MapValueEntryInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MapValueInput = {
  key: Scalars['String']['input'];
  mapValue: Array<MapValueEntryInput>;
};

export type MapValueType =
  | 'ADDRESS';

export type MissingAttachmentsNotification = Notification & {
  __typename: 'MissingAttachmentsNotification';
  attachments: Array<Attachment>;
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type MissingValuesNotification = Notification & {
  __typename: 'MissingValuesNotification';
  count: Scalars['Int']['output'];
  fields: Array<Field>;
  group: FieldGroup;
  id: Scalars['ID']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  addLegalEntityToLedgerGroup: LedgerBeneficiary;
  archiveDocumentSets: Array<DocumentSet>;
  archiveEmailSets: Array<EmailSet>;
  changeOperationStatus: Operation;
  createAccounts: Array<Account>;
  createAttachment: Attachment;
  createCancellationLedgerTransaction: LedgerTransaction;
  createCashSubscriptionLedgerTransaction: LedgerTransaction;
  createCashTransferLedgerTransaction: LedgerTransaction;
  createConversionLedgerTransaction: LedgerTransaction;
  createDonationDismembermentLedgerTransaction: LedgerTransaction;
  createDonationFullOwnershipLedgerTransaction: LedgerTransaction;
  createEmailSet: EmailSet;
  createEmissionLedgerTransaction: LedgerTransaction;
  createEquitySubscriptionLedgerTransaction: LedgerTransaction;
  createEquityTransferLedgerTransaction: LedgerTransaction;
  createField: Field;
  createFormSection: FormSection;
  createLedger: Ledger;
  createLedgerAccountForBeneficiary: LedgerAccount;
  createLedgerAssetType: LedgerAssetType;
  createLedgerGroup: LedgerGroup;
  createLegalEntityCompanies: Array<LegalEntityCompany>;
  createLegalEntityIndividuals: Array<LegalEntityIndividual>;
  createManualDocumentSet: DocumentSet;
  createOperation: Operation;
  createParticipants: Array<Participant>;
  createPledgeLedgerTransaction: LedgerTransaction;
  createPledgeReleaseLedgerTransaction: LedgerTransaction;
  createReconstitutionLedgerTransaction: LedgerTransaction;
  createReverseStockSplitLedgerTransaction: LedgerTransaction;
  createStockSplitLedgerTransaction: LedgerTransaction;
  createTemplateDocumentSet: DocumentSet;
  publishDocuments: Array<Document>;
  scheduleSendInvitationForParticipants: Array<Participant>;
  sendEmails: Array<Email>;
  setFieldOrder: Array<Field>;
  setFormSectionOrder: Array<FormSection>;
  unarchiveDocumentSets: Array<DocumentSet>;
  unarchiveEmailSets: Array<EmailSet>;
  unpublishDocuments: Array<Document>;
  updateAccount?: Maybe<Account>;
  updateAttachment: Attachment;
  updateEmailBody: Email;
  updateField: Field;
  updateFormSection: FormSection;
  updateParticipantValues: Array<Participant>;
  updateTemplate: Template;
};


export type MutationAddLegalEntityToLedgerGroupArgs = {
  ledgerGroupId: Scalars['ID']['input'];
  legalEntityId: Scalars['ID']['input'];
};


export type MutationArchiveDocumentSetsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationArchiveEmailSetsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationChangeOperationStatusArgs = {
  id: Scalars['ID']['input'];
  status: OperationStatus;
};


export type MutationCreateAccountsArgs = {
  accounts: Array<AccountCreateInput>;
  allowExisting?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateAttachmentArgs = {
  attachment: AttachmentCreateInput;
  operationId: Scalars['ID']['input'];
};


export type MutationCreateCancellationLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: CancellationLedgerTransactionCreateInput;
};


export type MutationCreateCashSubscriptionLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: CashSubscriptionLedgerTransactionCreateInput;
};


export type MutationCreateCashTransferLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: CashTransferLedgerTransactionCreateInput;
};


export type MutationCreateConversionLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: ConversionLedgerTransactionCreateInput;
};


export type MutationCreateDonationDismembermentLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: DonationDismembermentLedgerTransactionCreateInput;
};


export type MutationCreateDonationFullOwnershipLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: DonationFullOwnershipLedgerTransactionCreateInput;
};


export type MutationCreateEmailSetArgs = {
  emailSet: EmailSetCreateInput;
  operationId: Scalars['ID']['input'];
  participantIds: Array<Scalars['ID']['input']>;
};


export type MutationCreateEmissionLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: EmissionLedgerTransactionCreateInput;
};


export type MutationCreateEquitySubscriptionLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: EquitySubscriptionLedgerTransactionCreateInput;
};


export type MutationCreateEquityTransferLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: EquityTransferLedgerTransactionCreateInput;
};


export type MutationCreateFieldArgs = {
  field: FieldCreateInput;
  operationId: Scalars['ID']['input'];
};


export type MutationCreateFormSectionArgs = {
  formSection: FormSectionCreateInput;
  operationId: Scalars['ID']['input'];
};


export type MutationCreateLedgerArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateLedgerAccountForBeneficiaryArgs = {
  historicalBalances?: InputMaybe<Array<HistoricalFullOwnershipLedgerBalanceSetInput>>;
  ledgerBeneficiaryId: Scalars['ID']['input'];
  ledgerId: Scalars['ID']['input'];
  type: LedgerAccountTypeExcludingPledge;
};


export type MutationCreateLedgerAssetTypeArgs = {
  ledgerId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateLedgerGroupArgs = {
  ledgerId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateLegalEntityCompaniesArgs = {
  allowExisting?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntities: Array<LegalEntityCompanyCreate>;
};


export type MutationCreateLegalEntityIndividualsArgs = {
  allowExisting?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntities: Array<LegalEntityIndividualCreate>;
};


export type MutationCreateManualDocumentSetArgs = {
  name: Scalars['String']['input'];
  operationId: Scalars['ID']['input'];
};


export type MutationCreateOperationArgs = {
  operation: OperationCreateInput;
};


export type MutationCreateParticipantsArgs = {
  operationId: Scalars['ID']['input'];
  participants: Array<ParticipantCreateInput>;
};


export type MutationCreatePledgeLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: PledgeLedgerTransactionCreateInput;
};


export type MutationCreatePledgeReleaseLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: PledgeReleaseLedgerTransactionCreateInput;
};


export type MutationCreateReconstitutionLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: ReconstitutionLedgerTransactionCreateInput;
};


export type MutationCreateReverseStockSplitLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: ReverseStockSplitLedgerTransactionCreateInput;
};


export type MutationCreateStockSplitLedgerTransactionArgs = {
  ledgerId: Scalars['ID']['input'];
  transaction: StockSplitLedgerTransactionCreateInput;
};


export type MutationCreateTemplateDocumentSetArgs = {
  operationId: Scalars['ID']['input'];
  participantIds: Array<Scalars['ID']['input']>;
  templateDocumentSet: TemplateDocumentSetCreateInput;
};


export type MutationPublishDocumentsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationScheduleSendInvitationForParticipantsArgs = {
  operationId: Scalars['ID']['input'];
  participantIds: Array<Scalars['ID']['input']>;
};


export type MutationSendEmailsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationSetFieldOrderArgs = {
  fieldIds: Array<Scalars['ID']['input']>;
  operationId: Scalars['ID']['input'];
};


export type MutationSetFormSectionOrderArgs = {
  formSectionIds: Array<Scalars['ID']['input']>;
  operationId: Scalars['ID']['input'];
};


export type MutationUnarchiveDocumentSetsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationUnarchiveEmailSetsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationUnpublishDocumentsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationUpdateAccountArgs = {
  account: AccountUpdateInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateAttachmentArgs = {
  attachment: AttachmentUpdateInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateEmailBodyArgs = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationUpdateFieldArgs = {
  field: FieldUpdateInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateFormSectionArgs = {
  formSection: FormSectionUpdateInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateParticipantValuesArgs = {
  participantValues: Array<ParticipantValuesUpdateInput>;
};


export type MutationUpdateTemplateArgs = {
  template: TemplateUpdateInput;
};

export type Notification = {
  id: Scalars['ID']['output'];
};

export type NotificationType =
  | 'MISSING_ATTACHMENTS'
  | 'MISSING_VALUES'
  | 'REQUIRED_SIGNATURES';

export type Operation = {
  __typename: 'Operation';
  attachments: Array<Attachment>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  documentSet: DocumentSet;
  documentSets: Array<DocumentSet>;
  documentSetsConnection: OperationDocumentSetsConnection;
  emailSet: EmailSet;
  emailSets: Array<EmailSet>;
  fields: Array<Field>;
  formSections: Array<FormSection>;
  fromAddress?: Maybe<Scalars['String']['output']>;
  groups: Array<Group>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  participants: Array<Participant>;
  participantsConnection: OperationParticipantsConnection;
  status: OperationStatus;
  templates: Array<Template>;
};


export type OperationDocumentSetArgs = {
  id: Scalars['ID']['input'];
};


export type OperationDocumentSetsArgs = {
  excludeArchived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type OperationDocumentSetsConnectionArgs = {
  filters?: InputMaybe<OperationDocumentSetsConnectionFilters>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type OperationEmailSetArgs = {
  id: Scalars['ID']['input'];
};


export type OperationParticipantsConnectionArgs = {
  filters?: InputMaybe<OperationParticipantsConnectionFilters>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type OperationCreateInput = {
  code: Scalars['String']['input'];
  fromAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type OperationDocumentSetsConnection = Connection & {
  __typename: 'OperationDocumentSetsConnection';
  edges: Array<OperationDocumentSetsEdge>;
  id: Scalars['ID']['output'];
  pageInfo: PageInfo;
};

export type OperationDocumentSetsConnectionFilters = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OperationDocumentSetsEdge = {
  __typename: 'OperationDocumentSetsEdge';
  id: Scalars['ID']['output'];
  node: DocumentSet;
};

export type OperationParticipantsConnection = Connection & {
  __typename: 'OperationParticipantsConnection';
  edges: Array<OperationParticipantsEdge>;
  id: Scalars['ID']['output'];
  pageInfo: PageInfo;
};

export type OperationParticipantsConnectionFilters = {
  code?: InputMaybe<Array<Scalars['String']['input']>>;
  hasMissingAttachments?: InputMaybe<Scalars['Boolean']['input']>;
  hasMissingValues?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendingSignatures?: InputMaybe<Scalars['Boolean']['input']>;
  invited?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityType?: InputMaybe<Array<LegalEntityType>>;
  name?: InputMaybe<Array<Scalars['String']['input']>>;
  pendingInvitation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OperationParticipantsEdge = {
  __typename: 'OperationParticipantsEdge';
  id: Scalars['ID']['output'];
  node: Participant;
};

export type OperationStatus =
  | 'CLOSED'
  | 'DRAFT'
  | 'OPEN';

export type PageInfo = {
  __typename: 'PageInfo';
  current: Scalars['Int']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type Participant = {
  __typename: 'Participant';
  account: Account;
  attachments: Array<Attachment>;
  code: Scalars['String']['output'];
  documents: Array<Document>;
  emails: Array<Email>;
  fields: Array<Field>;
  groups: Array<Group>;
  id: Scalars['String']['output'];
  info: ParticipantKeyInfo;
  lastInvitationSentAt?: Maybe<Scalars['DateTime']['output']>;
  legalEntity: LegalEntity;
  notifications: Array<Notification>;
  operation: Operation;
  type: ParticipantType;
  uploadedAttachments: Array<UploadedAttachment>;
  values: Array<Value>;
};


export type ParticipantNotificationsArgs = {
  notificationTypes?: InputMaybe<Array<NotificationType>>;
};

export type ParticipantCreateInput = {
  legalEntityId: Scalars['ID']['input'];
  values?: InputMaybe<ValuesInput>;
};

export type ParticipantKeyInfo = {
  __typename: 'ParticipantKeyInfo';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ParticipantType;
  url: Scalars['String']['output'];
};

export type ParticipantType =
  | 'COMPANY'
  | 'INDIVIDUAL';

export type ParticipantValuesUpdateInput = {
  id: Scalars['ID']['input'];
  values: ValuesInput;
};

export type PledgeLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetType: LedgerTransactionPossiblyDismemberedAssetTypeInput;
  context: TransactionContextInput;
  debitorAccountId: Scalars['ID']['input'];
};

export type PledgeReleaseLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetType: LedgerTransactionPossiblyDismemberedAssetTypeInput;
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
  debitorAccountId: Scalars['ID']['input'];
};

export type Query = {
  __typename: 'Query';
  account?: Maybe<Account>;
  accountByEmail?: Maybe<Account>;
  accounts: Array<Account>;
  accountsByEmail: Array<Account>;
  currentAccount: Account;
  email?: Maybe<Email>;
  ledger?: Maybe<Ledger>;
  ledgerAccountsForBeneficiary: Array<LedgerAccount>;
  ledgerByName?: Maybe<Ledger>;
  ledgerGroupByName?: Maybe<LedgerGroup>;
  ledgers: Array<Ledger>;
  legalEntitiesByIdentifiers: Array<LegalEntity>;
  operation?: Maybe<Operation>;
  operationByCode?: Maybe<Operation>;
  operationByName?: Maybe<Operation>;
  operations: Array<Operation>;
  participant?: Maybe<Participant>;
  searchLedgerAccounts: Array<LedgerAccount>;
  searchLegalEntities: Array<LegalEntity>;
  template?: Maybe<Template>;
};


export type QueryAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAccountByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryAccountsArgs = {
  type?: InputMaybe<AccountType>;
};


export type QueryAccountsByEmailArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type QueryEmailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLedgerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLedgerAccountsForBeneficiaryArgs = {
  ledgerBeneficiaryId: Scalars['ID']['input'];
  ledgerId: Scalars['ID']['input'];
};


export type QueryLedgerByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryLedgerGroupByNameArgs = {
  ledgerId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type QueryLegalEntitiesByIdentifiersArgs = {
  identifiers: Array<Scalars['String']['input']>;
};


export type QueryOperationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOperationByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryOperationByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryOperationsArgs = {
  status?: InputMaybe<Array<OperationStatus>>;
};


export type QueryParticipantArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySearchLedgerAccountsArgs = {
  beneficiaryName: Scalars['String']['input'];
  ledgerAccountTypes?: InputMaybe<Array<LedgerAccountType>>;
  ledgerId: Scalars['ID']['input'];
};


export type QuerySearchLegalEntitiesArgs = {
  term: Scalars['String']['input'];
};


export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type ReconstitutionLedgerTransactionCreateInput = {
  amount: Scalars['Int']['input'];
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  creditorAccountId: Scalars['ID']['input'];
  dismembermentTransactionId: Scalars['ID']['input'];
};

export type RequiredSignaturesNotification = Notification & {
  __typename: 'RequiredSignaturesNotification';
  count: Scalars['Int']['output'];
  documents: Array<Document>;
  id: Scalars['ID']['output'];
};

export type ReverseStockSplitLedgerTransactionCreateInput = {
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  multiple: Scalars['Float']['input'];
};

export type Signatory = {
  __typename: 'Signatory';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  status: SignatoryStatus;
};

export type SignatoryStatus =
  | 'AWAITING_SEND'
  | 'AWAITING_SIGNATURE'
  | 'DECLINED'
  | 'ERROR'
  | 'SIGNED';

export type StockSplitLedgerTransactionCreateInput = {
  assetTypeId: Scalars['ID']['input'];
  context: TransactionContextInput;
  multiple: Scalars['Float']['input'];
};

export type Template = {
  __typename: 'Template';
  id: Scalars['ID']['output'];
  latestVersion: TemplateVersion;
  name: Scalars['String']['output'];
  target: TemplateTarget;
  type: TemplateType;
  versions: Array<TemplateVersion>;
};

export type TemplateDocumentSetCreateInput = {
  categories: Array<DocumentCategory>;
  format: DocumentFileFormat;
  name: Scalars['String']['input'];
  publishImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  signatureInfo?: InputMaybe<TemplateDocumentSetCreateSignatureInfoInput>;
  templateVersionId: Scalars['ID']['input'];
};

export type TemplateDocumentSetCreateSignatureInfoInput = {
  externalSignatories?: InputMaybe<Array<ExternalDocumentSignatoryInfoInput>>;
  participantOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type TemplateTarget =
  | 'DOCUMENT'
  | 'EMAIL';

export type TemplateType =
  | 'DYNAMIC'
  | 'STATIC';

export type TemplateUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateVersion = {
  __typename: 'TemplateVersion';
  createdAt: Scalars['DateTime']['output'];
  fields: Array<Field>;
  id: Scalars['ID']['output'];
  signatureCount: Scalars['Int']['output'];
  version: Scalars['DateTime']['output'];
};

export type TextFieldFormat =
  | 'EMAIL'
  | 'IBAN'
  | 'PHONE'
  | 'TEXT';

export type TextFieldParameters = {
  __typename: 'TextFieldParameters';
  textFormat: TextFieldFormat;
};

export type TextFieldParametersInput = {
  textFormat: TextFieldFormat;
};

export type TextValue = Value & {
  __typename: 'TextValue';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  textValue: Scalars['String']['output'];
};

export type TextValueInput = {
  key: Scalars['String']['input'];
  textValue: Scalars['String']['input'];
};

export type TransactionContextInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  occurredAt: Scalars['DateTime']['input'];
};

export type UploadedAttachment = {
  __typename: 'UploadedAttachment';
  attachment: Attachment;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  originalFileName?: Maybe<Scalars['String']['output']>;
};

export type Value = {
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type ValuesInput = {
  booleanValues?: InputMaybe<Array<BooleanValueInput>>;
  dateValues?: InputMaybe<Array<DateValueInput>>;
  floatValues?: InputMaybe<Array<FloatValueInput>>;
  lookupValues?: InputMaybe<Array<LookupValueInput>>;
  mapValues?: InputMaybe<Array<MapValueInput>>;
  textValues?: InputMaybe<Array<TextValueInput>>;
};

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  account: AccountUpdateInput;
}>;


export type UpdateAccountMutation = { __typename: 'Mutation', updateAccount?: { __typename: 'Account', id: string } | null };

export type CheckAccountEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type CheckAccountEmailQuery = { __typename: 'Query', accountByEmail?: { __typename: 'Account', id: string } | null };

export type OperationInfoFragment = { __typename: 'Operation', id: string, code: string, name: string, status: OperationStatus, createdAt: DateTime };

export type GetParticipationsForCurrentAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParticipationsForCurrentAccountQuery = { __typename: 'Query', currentAccount: { __typename: 'Account', id: string, participations: Array<{ __typename: 'Participant', id: string, info: { __typename: 'ParticipantKeyInfo', id: string, name: string }, operation: { __typename: 'Operation', id: string, code: string, name: string, status: OperationStatus, createdAt: DateTime }, notifications: Array<{ __typename: 'MissingAttachmentsNotification', count: number, id: string } | { __typename: 'MissingValuesNotification', count: number, group: FieldGroup, id: string } | { __typename: 'RequiredSignaturesNotification', count: number, id: string }> }> } };

export type GetOperationForParticipantQueryVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;


export type GetOperationForParticipantQuery = { __typename: 'Query', participant?: { __typename: 'Participant', id: string, operation: { __typename: 'Operation', id: string, code: string, name: string, status: OperationStatus, createdAt: DateTime } } | null };

export type ParticipantValuesFragment = { __typename: 'Participant', values: Array<{ __typename: 'BooleanValue', booleanValue: boolean, id: string, key: string } | { __typename: 'DateValue', dateValue: DateTime, id: string, key: string } | { __typename: 'FloatValue', floatValue: number, id: string, key: string } | { __typename: 'LookupValue', id: string, key: string, lookupValue: { __typename: 'LookupValueEntry', key: string, dictionary: LookupValueDictionary } } | { __typename: 'MapValue', id: string, key: string, mapValue: Array<{ __typename: 'MapValueEntry', key: string, value: string }> } | { __typename: 'TextValue', textValue: string, id: string, key: string }> };

export type GetAttachmentsForParticipantQueryVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;


export type GetAttachmentsForParticipantQuery = { __typename: 'Query', participant?: { __typename: 'Participant', id: string, uploadedAttachments: Array<{ __typename: 'UploadedAttachment', id: string, fileName: string, originalFileName?: string | null, attachment: { __typename: 'Attachment', id: string } }>, attachments: Array<{ __typename: 'Attachment', id: string, group: FieldGroup, type: AttachmentType, name: { __typename: 'LocalizedString', FR: string, EN?: string | null, IT?: string | null } }>, operation: { __typename: 'Operation', id: string, status: OperationStatus } } | null };

export type LocalizedStringFieldsFragment = { __typename: 'LocalizedString', FR: string, EN?: string | null, IT?: string | null };

export type GetValuesForParticipantQueryVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;


export type GetValuesForParticipantQuery = { __typename: 'Query', participant?: { __typename: 'Participant', id: string, operation: { __typename: 'Operation', id: string, status: OperationStatus }, info: { __typename: 'ParticipantKeyInfo', id: string, code: string, name: string }, fields: Array<{ __typename: 'Field', id: string, key: string, group: FieldGroup, position?: number | null, name: { __typename: 'LocalizedString', FR: string, EN?: string | null, IT?: string | null }, parameters: { __typename: 'DateFieldParameters', range: DateFieldRange } | { __typename: 'FloatFieldParameters', currency?: Currency | null, precision: number } | { __typename: 'LookupFieldParameters', dictionary: LookupValueDictionary } | { __typename: 'MapFieldParameters', subType: MapValueType } | { __typename: 'TextFieldParameters', textFormat: TextFieldFormat } }>, notifications: Array<{ __typename: 'MissingAttachmentsNotification', count: number, id: string } | { __typename: 'MissingValuesNotification', group: FieldGroup, count: number, id: string } | { __typename: 'RequiredSignaturesNotification', count: number, id: string }>, values: Array<{ __typename: 'BooleanValue', booleanValue: boolean, id: string, key: string } | { __typename: 'DateValue', dateValue: DateTime, id: string, key: string } | { __typename: 'FloatValue', floatValue: number, id: string, key: string } | { __typename: 'LookupValue', id: string, key: string, lookupValue: { __typename: 'LookupValueEntry', key: string, dictionary: LookupValueDictionary } } | { __typename: 'MapValue', id: string, key: string, mapValue: Array<{ __typename: 'MapValueEntry', key: string, value: string }> } | { __typename: 'TextValue', textValue: string, id: string, key: string }> } | null };

export type GetValueFormForParticipantQueryVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;


export type GetValueFormForParticipantQuery = { __typename: 'Query', participant?: { __typename: 'Participant', id: string, operation: { __typename: 'Operation', id: string, status: OperationStatus }, info: { __typename: 'ParticipantKeyInfo', id: string, code: string, name: string }, fields: Array<{ __typename: 'Field', id: string, key: string, group: FieldGroup, position?: number | null, formSection?: { __typename: 'FormSection', id: string, position?: number | null, name: { __typename: 'LocalizedString', FR: string, EN?: string | null, IT?: string | null } } | null, name: { __typename: 'LocalizedString', FR: string, EN?: string | null, IT?: string | null }, parameters: { __typename: 'DateFieldParameters', range: DateFieldRange } | { __typename: 'FloatFieldParameters', currency?: Currency | null, precision: number } | { __typename: 'LookupFieldParameters', dictionary: LookupValueDictionary } | { __typename: 'MapFieldParameters', subType: MapValueType } | { __typename: 'TextFieldParameters', textFormat: TextFieldFormat } }>, values: Array<{ __typename: 'BooleanValue', booleanValue: boolean, id: string, key: string } | { __typename: 'DateValue', dateValue: DateTime, id: string, key: string } | { __typename: 'FloatValue', floatValue: number, id: string, key: string } | { __typename: 'LookupValue', id: string, key: string, lookupValue: { __typename: 'LookupValueEntry', key: string, dictionary: LookupValueDictionary } } | { __typename: 'MapValue', id: string, key: string, mapValue: Array<{ __typename: 'MapValueEntry', key: string, value: string }> } | { __typename: 'TextValue', textValue: string, id: string, key: string }> } | null };

export type FieldDetailsFragment = { __typename: 'Field', id: string, key: string, group: FieldGroup, position?: number | null, name: { __typename: 'LocalizedString', FR: string, EN?: string | null, IT?: string | null }, parameters: { __typename: 'DateFieldParameters', range: DateFieldRange } | { __typename: 'FloatFieldParameters', currency?: Currency | null, precision: number } | { __typename: 'LookupFieldParameters', dictionary: LookupValueDictionary } | { __typename: 'MapFieldParameters', subType: MapValueType } | { __typename: 'TextFieldParameters', textFormat: TextFieldFormat } };

export type UpdateParticipantValuesMutationVariables = Exact<{
  participantValues: Array<ParticipantValuesUpdateInput> | ParticipantValuesUpdateInput;
}>;


export type UpdateParticipantValuesMutation = { __typename: 'Mutation', updateParticipantValues: Array<{ __typename: 'Participant', id: string, values: Array<{ __typename: 'BooleanValue', booleanValue: boolean, id: string, key: string } | { __typename: 'DateValue', dateValue: DateTime, id: string, key: string } | { __typename: 'FloatValue', floatValue: number, id: string, key: string } | { __typename: 'LookupValue', id: string, key: string, lookupValue: { __typename: 'LookupValueEntry', key: string, dictionary: LookupValueDictionary } } | { __typename: 'MapValue', id: string, key: string, mapValue: Array<{ __typename: 'MapValueEntry', key: string, value: string }> } | { __typename: 'TextValue', textValue: string, id: string, key: string }> }> };

export type GetDocumentsForParticipantQueryVariables = Exact<{
  participantId: Scalars['ID']['input'];
}>;


export type GetDocumentsForParticipantQuery = { __typename: 'Query', participant?: { __typename: 'Participant', id: string, info: { __typename: 'ParticipantKeyInfo', id: string, code: string, name: string }, notifications: Array<{ __typename: 'MissingAttachmentsNotification', count: number, id: string } | { __typename: 'MissingValuesNotification', group: FieldGroup, count: number, id: string } | { __typename: 'RequiredSignaturesNotification', count: number, id: string }>, documents: Array<{ __typename: 'Document', id: string, status: DocumentStatus, name: string, createdAt: DateTime, requiresSignature: boolean, categories: Array<DocumentCategory>, signatories: Array<{ __typename: 'Signatory', id: string, name: string, status: SignatoryStatus, order: number }>, participant: { __typename: 'Participant', id: string, info: { __typename: 'ParticipantKeyInfo', id: string, code: string, name: string } }, files: Array<{ __typename: 'DocumentFile', id: string, url: string, name: string, type: DocumentFileType, format: DocumentFileFormat }> }>, operation: { __typename: 'Operation', id: string, status: OperationStatus } } | null };

export const OperationInfoFragmentDoc = gql`
    fragment operationInfo on Operation {
  id
  code
  name
  status
  createdAt
}
    `;
export const ParticipantValuesFragmentDoc = gql`
    fragment participantValues on Participant {
  values {
    id
    key
    ... on TextValue {
      textValue
    }
    ... on DateValue {
      dateValue
    }
    ... on BooleanValue {
      booleanValue
    }
    ... on FloatValue {
      floatValue
    }
    ... on LookupValue {
      lookupValue {
        key
        dictionary
      }
    }
    ... on MapValue {
      mapValue {
        key
        value
      }
    }
  }
}
    `;
export const LocalizedStringFieldsFragmentDoc = gql`
    fragment LocalizedStringFields on LocalizedString {
  FR
  EN
  IT
}
    `;
export const FieldDetailsFragmentDoc = gql`
    fragment fieldDetails on Field {
  id
  key
  name {
    ...LocalizedStringFields
  }
  group
  position
  parameters {
    ... on TextFieldParameters {
      textFormat
    }
    ... on LookupFieldParameters {
      dictionary
    }
    ... on DateFieldParameters {
      range
    }
    ... on FloatFieldParameters {
      currency
      precision
    }
    ... on MapFieldParameters {
      subType
    }
  }
}
    ${LocalizedStringFieldsFragmentDoc}`;
export const UpdateAccountDocument = gql`
    mutation updateAccount($id: ID!, $account: AccountUpdateInput!) {
  updateAccount(id: $id, account: $account) {
    id
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const CheckAccountEmailDocument = gql`
    query checkAccountEmail($email: String!) {
  accountByEmail(email: $email) {
    id
  }
}
    `;

/**
 * __useCheckAccountEmailQuery__
 *
 * To run a query within a React component, call `useCheckAccountEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAccountEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAccountEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckAccountEmailQuery(baseOptions: Apollo.QueryHookOptions<CheckAccountEmailQuery, CheckAccountEmailQueryVariables> & ({ variables: CheckAccountEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAccountEmailQuery, CheckAccountEmailQueryVariables>(CheckAccountEmailDocument, options);
      }
export function useCheckAccountEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAccountEmailQuery, CheckAccountEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAccountEmailQuery, CheckAccountEmailQueryVariables>(CheckAccountEmailDocument, options);
        }
export function useCheckAccountEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckAccountEmailQuery, CheckAccountEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckAccountEmailQuery, CheckAccountEmailQueryVariables>(CheckAccountEmailDocument, options);
        }
export type CheckAccountEmailQueryHookResult = ReturnType<typeof useCheckAccountEmailQuery>;
export type CheckAccountEmailLazyQueryHookResult = ReturnType<typeof useCheckAccountEmailLazyQuery>;
export type CheckAccountEmailSuspenseQueryHookResult = ReturnType<typeof useCheckAccountEmailSuspenseQuery>;
export type CheckAccountEmailQueryResult = Apollo.QueryResult<CheckAccountEmailQuery, CheckAccountEmailQueryVariables>;
export const GetParticipationsForCurrentAccountDocument = gql`
    query getParticipationsForCurrentAccount {
  currentAccount {
    id
    participations {
      id
      info {
        id
        name
      }
      operation {
        ...operationInfo
      }
      notifications {
        id
        ... on MissingAttachmentsNotification {
          count
        }
        ... on MissingValuesNotification {
          count
          group
        }
        ... on RequiredSignaturesNotification {
          count
        }
      }
    }
  }
}
    ${OperationInfoFragmentDoc}`;

/**
 * __useGetParticipationsForCurrentAccountQuery__
 *
 * To run a query within a React component, call `useGetParticipationsForCurrentAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationsForCurrentAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationsForCurrentAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParticipationsForCurrentAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetParticipationsForCurrentAccountQuery, GetParticipationsForCurrentAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipationsForCurrentAccountQuery, GetParticipationsForCurrentAccountQueryVariables>(GetParticipationsForCurrentAccountDocument, options);
      }
export function useGetParticipationsForCurrentAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipationsForCurrentAccountQuery, GetParticipationsForCurrentAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipationsForCurrentAccountQuery, GetParticipationsForCurrentAccountQueryVariables>(GetParticipationsForCurrentAccountDocument, options);
        }
export function useGetParticipationsForCurrentAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetParticipationsForCurrentAccountQuery, GetParticipationsForCurrentAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetParticipationsForCurrentAccountQuery, GetParticipationsForCurrentAccountQueryVariables>(GetParticipationsForCurrentAccountDocument, options);
        }
export type GetParticipationsForCurrentAccountQueryHookResult = ReturnType<typeof useGetParticipationsForCurrentAccountQuery>;
export type GetParticipationsForCurrentAccountLazyQueryHookResult = ReturnType<typeof useGetParticipationsForCurrentAccountLazyQuery>;
export type GetParticipationsForCurrentAccountSuspenseQueryHookResult = ReturnType<typeof useGetParticipationsForCurrentAccountSuspenseQuery>;
export type GetParticipationsForCurrentAccountQueryResult = Apollo.QueryResult<GetParticipationsForCurrentAccountQuery, GetParticipationsForCurrentAccountQueryVariables>;
export const GetOperationForParticipantDocument = gql`
    query getOperationForParticipant($participantId: ID!) {
  participant(id: $participantId) {
    id
    operation {
      ...operationInfo
    }
  }
}
    ${OperationInfoFragmentDoc}`;

/**
 * __useGetOperationForParticipantQuery__
 *
 * To run a query within a React component, call `useGetOperationForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationForParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetOperationForParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetOperationForParticipantQuery, GetOperationForParticipantQueryVariables> & ({ variables: GetOperationForParticipantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperationForParticipantQuery, GetOperationForParticipantQueryVariables>(GetOperationForParticipantDocument, options);
      }
export function useGetOperationForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperationForParticipantQuery, GetOperationForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperationForParticipantQuery, GetOperationForParticipantQueryVariables>(GetOperationForParticipantDocument, options);
        }
export function useGetOperationForParticipantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOperationForParticipantQuery, GetOperationForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOperationForParticipantQuery, GetOperationForParticipantQueryVariables>(GetOperationForParticipantDocument, options);
        }
export type GetOperationForParticipantQueryHookResult = ReturnType<typeof useGetOperationForParticipantQuery>;
export type GetOperationForParticipantLazyQueryHookResult = ReturnType<typeof useGetOperationForParticipantLazyQuery>;
export type GetOperationForParticipantSuspenseQueryHookResult = ReturnType<typeof useGetOperationForParticipantSuspenseQuery>;
export type GetOperationForParticipantQueryResult = Apollo.QueryResult<GetOperationForParticipantQuery, GetOperationForParticipantQueryVariables>;
export const GetAttachmentsForParticipantDocument = gql`
    query getAttachmentsForParticipant($participantId: ID!) {
  participant(id: $participantId) {
    id
    uploadedAttachments {
      id
      fileName
      originalFileName
      attachment {
        id
      }
    }
    attachments {
      id
      name {
        ...LocalizedStringFields
      }
      group
      type
    }
    operation {
      id
      status
    }
  }
}
    ${LocalizedStringFieldsFragmentDoc}`;

/**
 * __useGetAttachmentsForParticipantQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsForParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetAttachmentsForParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetAttachmentsForParticipantQuery, GetAttachmentsForParticipantQueryVariables> & ({ variables: GetAttachmentsForParticipantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentsForParticipantQuery, GetAttachmentsForParticipantQueryVariables>(GetAttachmentsForParticipantDocument, options);
      }
export function useGetAttachmentsForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentsForParticipantQuery, GetAttachmentsForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentsForParticipantQuery, GetAttachmentsForParticipantQueryVariables>(GetAttachmentsForParticipantDocument, options);
        }
export function useGetAttachmentsForParticipantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAttachmentsForParticipantQuery, GetAttachmentsForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAttachmentsForParticipantQuery, GetAttachmentsForParticipantQueryVariables>(GetAttachmentsForParticipantDocument, options);
        }
export type GetAttachmentsForParticipantQueryHookResult = ReturnType<typeof useGetAttachmentsForParticipantQuery>;
export type GetAttachmentsForParticipantLazyQueryHookResult = ReturnType<typeof useGetAttachmentsForParticipantLazyQuery>;
export type GetAttachmentsForParticipantSuspenseQueryHookResult = ReturnType<typeof useGetAttachmentsForParticipantSuspenseQuery>;
export type GetAttachmentsForParticipantQueryResult = Apollo.QueryResult<GetAttachmentsForParticipantQuery, GetAttachmentsForParticipantQueryVariables>;
export const GetValuesForParticipantDocument = gql`
    query getValuesForParticipant($participantId: ID!) {
  participant(id: $participantId) {
    id
    operation {
      id
      status
    }
    info {
      id
      code
      name
    }
    fields {
      ...fieldDetails
    }
    ...participantValues
    notifications(notificationTypes: MISSING_VALUES) {
      id
      ... on MissingValuesNotification {
        group
        count
      }
      ... on MissingAttachmentsNotification {
        count
      }
      ... on RequiredSignaturesNotification {
        count
      }
    }
  }
}
    ${FieldDetailsFragmentDoc}
${ParticipantValuesFragmentDoc}`;

/**
 * __useGetValuesForParticipantQuery__
 *
 * To run a query within a React component, call `useGetValuesForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValuesForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValuesForParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetValuesForParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetValuesForParticipantQuery, GetValuesForParticipantQueryVariables> & ({ variables: GetValuesForParticipantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValuesForParticipantQuery, GetValuesForParticipantQueryVariables>(GetValuesForParticipantDocument, options);
      }
export function useGetValuesForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValuesForParticipantQuery, GetValuesForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValuesForParticipantQuery, GetValuesForParticipantQueryVariables>(GetValuesForParticipantDocument, options);
        }
export function useGetValuesForParticipantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetValuesForParticipantQuery, GetValuesForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetValuesForParticipantQuery, GetValuesForParticipantQueryVariables>(GetValuesForParticipantDocument, options);
        }
export type GetValuesForParticipantQueryHookResult = ReturnType<typeof useGetValuesForParticipantQuery>;
export type GetValuesForParticipantLazyQueryHookResult = ReturnType<typeof useGetValuesForParticipantLazyQuery>;
export type GetValuesForParticipantSuspenseQueryHookResult = ReturnType<typeof useGetValuesForParticipantSuspenseQuery>;
export type GetValuesForParticipantQueryResult = Apollo.QueryResult<GetValuesForParticipantQuery, GetValuesForParticipantQueryVariables>;
export const GetValueFormForParticipantDocument = gql`
    query getValueFormForParticipant($participantId: ID!) {
  participant(id: $participantId) {
    id
    operation {
      id
      status
    }
    info {
      id
      code
      name
    }
    ...participantValues
    fields {
      ...fieldDetails
      formSection {
        id
        position
        name {
          ...LocalizedStringFields
        }
      }
    }
  }
}
    ${ParticipantValuesFragmentDoc}
${FieldDetailsFragmentDoc}
${LocalizedStringFieldsFragmentDoc}`;

/**
 * __useGetValueFormForParticipantQuery__
 *
 * To run a query within a React component, call `useGetValueFormForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueFormForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValueFormForParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetValueFormForParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetValueFormForParticipantQuery, GetValueFormForParticipantQueryVariables> & ({ variables: GetValueFormForParticipantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValueFormForParticipantQuery, GetValueFormForParticipantQueryVariables>(GetValueFormForParticipantDocument, options);
      }
export function useGetValueFormForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValueFormForParticipantQuery, GetValueFormForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValueFormForParticipantQuery, GetValueFormForParticipantQueryVariables>(GetValueFormForParticipantDocument, options);
        }
export function useGetValueFormForParticipantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetValueFormForParticipantQuery, GetValueFormForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetValueFormForParticipantQuery, GetValueFormForParticipantQueryVariables>(GetValueFormForParticipantDocument, options);
        }
export type GetValueFormForParticipantQueryHookResult = ReturnType<typeof useGetValueFormForParticipantQuery>;
export type GetValueFormForParticipantLazyQueryHookResult = ReturnType<typeof useGetValueFormForParticipantLazyQuery>;
export type GetValueFormForParticipantSuspenseQueryHookResult = ReturnType<typeof useGetValueFormForParticipantSuspenseQuery>;
export type GetValueFormForParticipantQueryResult = Apollo.QueryResult<GetValueFormForParticipantQuery, GetValueFormForParticipantQueryVariables>;
export const UpdateParticipantValuesDocument = gql`
    mutation updateParticipantValues($participantValues: [ParticipantValuesUpdateInput!]!) {
  updateParticipantValues(participantValues: $participantValues) {
    id
    ...participantValues
  }
}
    ${ParticipantValuesFragmentDoc}`;
export type UpdateParticipantValuesMutationFn = Apollo.MutationFunction<UpdateParticipantValuesMutation, UpdateParticipantValuesMutationVariables>;

/**
 * __useUpdateParticipantValuesMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantValuesMutation, { data, loading, error }] = useUpdateParticipantValuesMutation({
 *   variables: {
 *      participantValues: // value for 'participantValues'
 *   },
 * });
 */
export function useUpdateParticipantValuesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateParticipantValuesMutation, UpdateParticipantValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateParticipantValuesMutation, UpdateParticipantValuesMutationVariables>(UpdateParticipantValuesDocument, options);
      }
export type UpdateParticipantValuesMutationHookResult = ReturnType<typeof useUpdateParticipantValuesMutation>;
export type UpdateParticipantValuesMutationResult = Apollo.MutationResult<UpdateParticipantValuesMutation>;
export type UpdateParticipantValuesMutationOptions = Apollo.BaseMutationOptions<UpdateParticipantValuesMutation, UpdateParticipantValuesMutationVariables>;
export const GetDocumentsForParticipantDocument = gql`
    query getDocumentsForParticipant($participantId: ID!) {
  participant(id: $participantId) {
    id
    info {
      id
      code
      name
    }
    notifications(notificationTypes: REQUIRED_SIGNATURES) {
      id
      ... on MissingValuesNotification {
        group
        count
      }
      ... on MissingAttachmentsNotification {
        count
      }
      ... on RequiredSignaturesNotification {
        count
      }
    }
    documents {
      id
      status
      name
      createdAt
      requiresSignature
      categories
      signatories {
        id
        name
        status
        order
      }
      participant {
        id
        info {
          id
          code
          name
        }
      }
      files {
        id
        url
        name
        type
        format
      }
    }
    operation {
      id
      status
    }
  }
}
    `;

/**
 * __useGetDocumentsForParticipantQuery__
 *
 * To run a query within a React component, call `useGetDocumentsForParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsForParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsForParticipantQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetDocumentsForParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentsForParticipantQuery, GetDocumentsForParticipantQueryVariables> & ({ variables: GetDocumentsForParticipantQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsForParticipantQuery, GetDocumentsForParticipantQueryVariables>(GetDocumentsForParticipantDocument, options);
      }
export function useGetDocumentsForParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsForParticipantQuery, GetDocumentsForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsForParticipantQuery, GetDocumentsForParticipantQueryVariables>(GetDocumentsForParticipantDocument, options);
        }
export function useGetDocumentsForParticipantSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDocumentsForParticipantQuery, GetDocumentsForParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDocumentsForParticipantQuery, GetDocumentsForParticipantQueryVariables>(GetDocumentsForParticipantDocument, options);
        }
export type GetDocumentsForParticipantQueryHookResult = ReturnType<typeof useGetDocumentsForParticipantQuery>;
export type GetDocumentsForParticipantLazyQueryHookResult = ReturnType<typeof useGetDocumentsForParticipantLazyQuery>;
export type GetDocumentsForParticipantSuspenseQueryHookResult = ReturnType<typeof useGetDocumentsForParticipantSuspenseQuery>;
export type GetDocumentsForParticipantQueryResult = Apollo.QueryResult<GetDocumentsForParticipantQuery, GetDocumentsForParticipantQueryVariables>;