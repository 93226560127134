import { ExportParticipantValuesRequest, axios } from '@publica/common'
import { ApiEndpoints } from '@publica/endpoints'

import { getAuthHeaders } from '../auth'

export class ParticipantsClient {
    constructor(
        private endpoints: ApiEndpoints,
        private getToken?: () => Promise<string>
    ) {}

    async export(request: ExportParticipantValuesRequest): Promise<Blob> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        return axios
            .post<Blob>(this.endpoints.participants.export(), request, {
                responseType: 'blob',
                headers,
            })
            .then(({ data }) => data)
    }
}
