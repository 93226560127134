import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const dateFormatSchema = z.enum(['CONDENSED', 'FULL_LENGTH'])

export type DateFormat = z.infer<typeof dateFormatSchema>

const dateFormats: Record<KnownLocale, Record<DateFormat, string>> = {
    FR: {
        CONDENSED: 'Court (AAAA/MM/JJ)',
        FULL_LENGTH: 'Toutes lettres',
    },
    EN: {
        CONDENSED: 'Short (YYYY/MM/DD)',
        FULL_LENGTH: 'Full length',
    },
    IT: {
        CONDENSED: 'Breve (YYYY/MM/DD)',
        FULL_LENGTH: 'Per esteso',
    },
}

export const dateFormatLookup = new LookupList(dateFormats)
