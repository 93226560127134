import { Card } from 'antd'
import isNil from 'lodash/isNil'

import { operationIsOpen } from '@publica/common'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { useAttachmentsWithUploads } from '@publica/ui-web-utils'

import { useGetAttachmentsForParticipantQuery } from '../../../data'
import { useParticipationContext } from '../context'
import { AttachmentsIndex } from './AttachmentsIndex'

const useAttachmentsTranslation = createUseTranslation({
    EN: {
        attachments: 'KYC Documents',
    },
    FR: {
        attachments: 'Documents KYC',
    },
})

export const Attachments: FC = () => {
    const { t } = useAttachmentsTranslation()
    const ctx = useParticipationContext()

    const { data, loading } = useGetAttachmentsForParticipantQuery({
        variables: {
            participantId: ctx.participant.id,
        },
        pollInterval: __SLOW_POLLING__,
    })

    const participant = data?.participant
    const operation = participant?.operation

    const uploadedAttachments = participant?.uploadedAttachments ?? []
    const attachments = participant?.attachments ?? []
    const readonly = isNil(operation) || !operationIsOpen(operation)

    const attachmentsWithUploaded = useAttachmentsWithUploads(attachments, uploadedAttachments, readonly)

    return (
        <Card title={t('attachments')}>
            <AttachmentsIndex loading={loading} attachments={attachmentsWithUploaded} />
        </Card>
    )
}
