import { HolderOutlined, SearchOutlined } from '@ant-design/icons'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Empty, Input, Table, TableProps } from 'antd'
import { ColumnType, FilterDropdownProps, TablePaginationConfig } from 'antd/lib/table/interface'
import isArray from 'lodash/isArray'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'
import { matches } from '@publica/utils'

import { icons } from '..'

type FilterTableProps<I extends { id: string }> = Omit<
    TableProps<I>,
    'columns' | 'components' | 'onRow' | 'key' | 'scroll' | 'pagination'
> & {
    columns: (FilterColumnType<I> | ColumnType<I>)[]
    emptyText?: string
    canDragSort?: boolean
    onSort?: (items: I[]) => void
    scrollsHorizontally?: boolean
    pagination?: {
        pageSize: number
        current: number
        total: number
    }
}

const modifiers = [restrictToParentElement]

const sortKey = '__sortHandle'

const useSortStyles = createUseStyles({
    handle: {
        cursor: 'grab',
    },
})

const useFilterTableTranslation = createUseTranslation({
    FR: {
        sort: 'Trier',
        sortDesc: 'Trier par ordre décroissant',
        sortAsc: 'Trier par order croissant',
        cancel: 'Annuler le tri',
        noData: 'Aucune donnée',
    },
    EN: {
        sort: 'Sort',
        sortDesc: 'Sort by descending order',
        sortAsc: 'Sort by ascending order',
        cancel: 'Cancel sort',
        noData: 'No data',
    },
})

export const FilterTable = <I extends { id: string }>({
    dataSource,
    emptyText,
    children,
    columns,
    canDragSort = false,
    onSort,
    pagination,
    scrollsHorizontally,
    ...props
}: FilterTableProps<I> & { children?: React.ReactNode }) => {
    const [data, setData] = useState(dataSource ?? [])
    const { t } = useFilterTableTranslation()

    useEffect(() => {
        setData(dataSource ?? [])
    }, [dataSource])

    const sortStyles = useSortStyles()

    const tableLocale = useMemo(
        () => ({
            sortTitle: t('sort'),
            triggerDesc: t('sortDesc'),
            triggerAsc: t('sortAsc'),
            cancelSort: t('cancel'),
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText ?? t('noData')} />,
        }),
        [emptyText, t]
    )

    const columnsWithFilters = useMemo(() => columns.map(buildColumnFilters), [columns])

    const onRow = useCallback<NonNullable<TableProps<I>['onRow']>>(
        (record, index) => {
            const attrs = {
                record,
                index,
                canDragSort,
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return attrs as React.HTMLAttributes<any>
        },
        [canDragSort]
    )

    const sortableColumns = useMemo<FilterColumnType<I>[]>(() => {
        if (!canDragSort) {
            return columnsWithFilters
        }

        return [
            {
                key: sortKey,
                render: () => <HolderOutlined className={sortStyles.handle} />,
                width: 50,
            },
            ...columnsWithFilters,
        ]
    }, [canDragSort, columnsWithFilters, sortStyles.handle])

    const sortableComponents = useMemo<TableProps<I>['components']>(
        () => ({
            body: {
                row: Row,
                wrapper: (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
                    // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                    <SortableContext items={[...data]} strategy={verticalListSortingStrategy}>
                        <tbody {...props} />
                    </SortableContext>
                ),
            },
        }),
        [data]
    )

    const onDragEnd = useCallback(
        (event: DragEndEvent) => {
            const active = event.active
            const over = event.over!
            if (active.id !== over.id) {
                setData(data => {
                    const oldIndex = data.findIndex(data => data.id === active.id)
                    const newIndex = data.findIndex(data => data.id === over.id)
                    const sorted = arrayMove([...data], oldIndex, newIndex)

                    if (onSort !== undefined) {
                        onSort(sorted)
                    }
                    return sorted
                })
            }
        },
        [onSort]
    )

    const paginationConfig = useMemo<TablePaginationConfig | false>(() => {
        if (pagination === undefined) {
            return false
        }

        const { pageSize, current, total } = pagination

        return {
            current,
            pageSize,
            total,
        }
    }, [pagination])

    return (
        <DndContext modifiers={modifiers} onDragEnd={onDragEnd}>
            <Table<I>
                bordered={true}
                dataSource={data}
                pagination={paginationConfig}
                locale={tableLocale}
                columns={sortableColumns}
                components={sortableComponents}
                onRow={onRow}
                rowKey="id"
                size="small"
                scroll={scrollsHorizontally ? horizontalScrolling : undefined}
                {...props}
            >
                {children}
            </Table>
        </DndContext>
    )
}

const horizontalScrolling = { x: 'max-content' }

const buildColumnFilters = <I,>(column: FilterColumnType<I> | ColumnType<I>): ColumnType<I> => {
    const filterValue = (column as FilterColumnType<I>).filterValue

    if (filterValue === undefined || filterValue === false) {
        return column
    }

    return {
        ...column,
        filterDropdown: props => <FilterDropdown {...props} />,
        onFilter:
            filterValue === true
                ? undefined
                : (value, record) => matches.matchesFilter(filterValue(record), value.toString()),
        filterIcon: filtered => <SearchOutlined color={filtered ? colors.primary : undefined} />,
    }
}

type SearchIconProps = {
    filtered: boolean
}

export const SearchIcon: FC<SearchIconProps> = ({ filtered }) => (
    <SearchOutlined color={filtered ? colors.primary : undefined} />
)

export type FilterColumnType<I> = Omit<
    ColumnType<I>,
    | 'filterDropdown'
    | 'filters'
    | 'filterMultiple'
    | 'defaultFilteredValue'
    | 'filterIcon'
    | 'filterMode'
    | 'filterSearch'
    | 'onFilter'
    | 'onFilterDropdownVisibleChange'
> & {
    filterValue?: boolean | ((record: I) => string)
}

const FilterDropdown = ({ confirm, setSelectedKeys }: FilterDropdownProps) => {
    const close = useCallback(() => {
        confirm()
    }, [confirm])

    const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        e => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
            confirm({
                closeDropdown: false,
            })
        },
        [confirm, setSelectedKeys]
    )

    return <Input onChange={onChange} prefix={icons.Search} allowClear={true} onPressEnter={close} />
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    index: number
    record: { id: string } | undefined
    canDragSort: boolean
}

const Row = ({ record, canDragSort, ...props }: RowProps) => {
    if (record === undefined || !canDragSort) {
        return <tr {...props} />
    } else {
        return <SortableRow {...props} record={record} />
    }
}

const SortableRow = ({
    record,
    style,
    children,
    ...restProps
}: Omit<RowProps, 'record' | 'canDragSort'> & { record: { id: string } }) => {
    const { attributes, listeners, transition, transform, setNodeRef } = useSortable({ id: record.id })

    const rowStyle = useMemo<React.CSSProperties>(
        () => ({
            ...style,
            transition,
            transform: CSS.Transform.toString(transform),
        }),
        [style, transform, transition]
    )

    if (!isArray(children)) {
        return <tr {...restProps}>{children}</tr>
    }

    return (
        <tr ref={setNodeRef} style={rowStyle} {...restProps} {...attributes}>
            {(children as ReactElement[]).map(child => {
                const { render, record, renderIndex } = child.props
                return child.key === sortKey ? (
                    <td key={child.key} {...listeners}>
                        {render(undefined, record, renderIndex)}
                    </td>
                ) : (
                    child
                )
            })}
        </tr>
    )
}
