import { AxiosProgressEvent } from 'axios'
import FormData from 'form-data'

import {
    UploadAttachmentRequest,
    UploadAttachmentResponse,
    axios,
    uploadAttachmentResponseSchema,
} from '@publica/common'
import { ApiEndpoints } from '@publica/endpoints'

import { getAuthHeaders } from '../auth'

export class AttachmentsClient {
    constructor(
        private endpoints: ApiEndpoints,
        private getToken?: () => Promise<string>
    ) {}

    async download(id: string): Promise<Blob> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        return axios
            .get<Blob>(this.endpoints.attachments.download(id), {
                responseType: 'blob',
                headers,
            })
            .then(({ data }) => data)
    }

    async upload(
        request: UploadAttachmentRequest & { id: string },
        file: File,
        onUploadProgress?: (event: AxiosProgressEvent) => void
    ): Promise<UploadAttachmentResponse> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)
        const formData = new FormData()

        // The 'file' has to match what the server expects
        formData.append('file', file)
        formData.append('participantId', request.participantId)

        return axios
            .post<unknown>(this.endpoints.attachments.upload(request.id), formData, {
                headers,
                onUploadProgress,
            })
            .then(({ data }) => uploadAttachmentResponseSchema.parse(data))
    }

    async delete(id: string): Promise<void> {
        const headers: Record<string, string> = await getAuthHeaders(this.getToken)

        await axios.delete(this.endpoints.attachments.delete(id), {
            headers,
        })
    }
}
