import { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'
import { Layout, NotFound } from '@publica/ui-web-components'

import { useTermsTranslations } from '../terms'
import { Landing, Participation, Terms } from './pages'
import { Account } from './pages/account'

const landing = <Landing />
const participation = <Participation />
const notFound = <NotFound />
const terms = <Terms />
const account = <Account />

export const App: FC = () => {
    const { t } = useTermsTranslations()

    const footerTerms = useMemo(
        () => (
            <div>
                <Link to="/terms">{t('terms')}</Link>
            </div>
        ),
        [t]
    )

    return (
        <Layout footerChildren={footerTerms}>
            <Routes>
                <Route index element={landing} />
                <Route path="/terms" element={terms} />
                <Route path="/account" element={account} />
                <Route path=":participantId/*" element={participation} />
                <Route path="*" element={notFound} />
            </Routes>
        </Layout>
    )
}

export default App
