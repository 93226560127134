import {
    ApolloClient,
    ApolloLink,
    ApolloProvider as BaseApolloProvider,
    HttpLink,
    InMemoryCache,
    from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { FunctionsMap, withScalars } from 'apollo-link-scalars'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
import reduce from 'lodash/reduce'
import { suspend } from 'suspend-react'

import { schema } from '@publica/api-graphql'
import { platformHeaders } from '@publica/common'
import { apiEndpointsWithHost } from '@publica/endpoints'
import { DateTimeScalar } from '@publica/graphql'
import { SpanKind, withSpan } from '@publica/trace'
import { useAuthState } from '@publica/ui-common-auth'
import { logger } from '@publica/ui-common-logger'
import { FC, NetworkError, useConfig } from '@publica/ui-common-utils'

const operationNameHeader = 'x-graphql-operation'

const tracingFetch: typeof fetch = async (input: RequestInfo | URL, init?: RequestInit) => {
    const headers = init?.headers
    let operationName: string | undefined | null

    if (headers !== undefined) {
        if (isArray(headers)) {
            const pair = headers.find(([key]) => key === operationNameHeader)
            operationName = (pair ?? [])[1]
        } else if (headers instanceof Headers) {
            operationName = headers.get(operationNameHeader)
        } else {
            operationName = headers[operationNameHeader]
        }
    }

    operationName = operationName ?? 'Unknown'

    return withSpan(
        `GraphQL:${operationName}`,
        async () => {
            return fetch(input, init)
        },
        {
            kind: SpanKind.CLIENT,
            attributes: {
                operationName,
            },
        }
    )
}

const typesMap: FunctionsMap = {
    DateTime: new DateTimeScalar(),
}

const errorPathAsString = (path: Readonly<(string | number)[]>): string =>
    reduce(path, (currPath, el) => currPath + (isString(el) ? `->${el}` : `[${el}]`), '(root)')

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors !== undefined && graphQLErrors.length > 0) {
        graphQLErrors.forEach(({ message, path }) => {
            const errorPath = errorPathAsString(path ?? [])
            logger.error(`GraphQL Error: ${message}, ${errorPath}`, { labels: { errorPath } })
        })

        throw new Error('GraphQL error')
    }

    if (networkError) {
        logger.error('Network error', { error: networkError })
        throw new NetworkError(networkError.message)
    }
})

export type CreateApolloClientOptions = {
    uri: string
    getToken: () => Promise<string>
    cache?: InMemoryCache
}

export const createApolloClient = async ({ uri, getToken, cache }: CreateApolloClientOptions) => {
    const retryLink = new RetryLink({
        attempts: {
            max: 30,

            retryIf: error => {
                const statusCode = (error as { statusCode?: number }).statusCode

                if (statusCode !== undefined) {
                    if (statusCode >= 500) {
                        return true
                    }
                    return false
                }

                return true
            },
        },
    })

    // Handle custom scalars
    const scalarLink = withScalars({ schema, typesMap })

    // Token
    const authLink = setContext(async operation => {
        const token = await getToken()

        return {
            headers: {
                authorization: `Bearer ${token}`,
                // Push the operation name into a header for tracing
                [operationNameHeader]: operation.operationName,
            },
        }
    })

    // Terminating link
    const httpLink = new HttpLink({ uri, headers: platformHeaders, fetch: tracingFetch })

    // The cast is important, due to a type issue
    // https://github.com/apollographql/apollo-client/issues/10146
    const links = [errorLink, retryLink, scalarLink, authLink, httpLink] as ApolloLink[]

    return new ApolloClient({
        link: from(links),
        cache: cache ?? new InMemoryCache(),
        connectToDevTools: __DEBUG__,
    })
}

type ApolloProviderProps = {
    cache?: InMemoryCache
}

export const ApolloProvider: FC<ApolloProviderProps> = ({ children, cache }) => {
    const config = useConfig()
    const { state } = useAuthState()

    const client = suspend(
        async () =>
            createApolloClient({
                uri: apiEndpointsWithHost(config.apiHost).graphql(),
                getToken: async () => (await state.getAccountToken()).token.accessToken,
                cache,
            }),
        [cache, config.apiHost]
    )

    return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>
}
