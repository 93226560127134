import { Collapse, Empty } from 'antd'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { createUseTranslation, getCurrentLocale } from '@publica/ui-common-i18n'
import { documentCategoryLabel } from '@publica/ui-common-labels'
import { FC } from '@publica/ui-common-utils'
import {
    DocumentActionsProps,
    DocumentControlsProps,
    DocumentTable,
    DocumentTableProps,
} from '@publica/ui-web-components'
import { ArrayItem } from '@publica/utils'

import { DocumentCategory } from '../../../../data'
import { DocumentActions } from '../DocumentActions'
import { DocumentControls } from '../DocumentControls'

type Document = ArrayItem<DocumentTableProps['documents']> & { categories: DocumentCategory[] }

const getTitleForDocumentCategory = (category: DocumentCategoryWithUncategorised): string => {
    switch (category) {
        case 'BANK':
        case 'FINAL':
        case 'DRAFT':
        case 'INFORMATION':
            return documentCategoryLabel(category)
        case 'UNCATEGORISED':
            switch (getCurrentLocale()) {
                case 'FR':
                    return 'Documents sans rubrique'
                case 'EN':
                // TRANSLATE: translation
                // eslint-disable-next-line no-fallthrough
                case 'IT':
                    return 'Uncategorised documents'
            }
    }
}

const documentCategoryOrder = ['INFORMATION', 'DRAFT', 'FINAL', 'BANK', 'UNCATEGORISED'] as const

type DocumentCategoryWithUncategorised = DocumentCategory | 'UNCATEGORISED'

type DocumentIndexProps = {
    operationId: string
    documents: Document[]
}

const useDocumentIndexTranslation = createUseTranslation({
    FR: {
        noDocuments: `Vous n'avez aucun document`,
    },
    EN: {
        noDocuments: 'You do not have any documents',
    },
})

const useDocumentSectionStyles = createUseStyles({
    section: {
        margin: 20,
    },
})

export const DocumentsIndex: FC<DocumentIndexProps> = ({ documents, operationId }) => {
    const documentSectionStyle = useDocumentSectionStyles()
    const { t } = useDocumentIndexTranslation()

    const documentsByCategory = useMemo(() => {
        const documentsWithoutCategories = documents.filter(doc => doc.categories.length === 0)

        const documentsByCategory: Record<DocumentCategoryWithUncategorised, Document[]> = {
            BANK: [],
            FINAL: [],
            DRAFT: [],
            INFORMATION: [],
            UNCATEGORISED: documentsWithoutCategories,
        }

        return documents.reduce((docsByCategory, doc) => {
            doc.categories.forEach(cat => {
                docsByCategory[cat].push(doc)
            })

            return docsByCategory
        }, documentsByCategory)
    }, [documents])

    if (documents.length === 0) {
        return <Empty description={t('noDocuments')} />
    }

    return (
        <>
            {documentCategoryOrder.map(category => {
                if (documentsByCategory[category].length === 0) {
                    return null
                }

                // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                const items = [
                    {
                        key: category,
                        label: getTitleForDocumentCategory(category),
                        children: (
                            <DocumentTable
                                operationId={operationId}
                                bordered={true}
                                documents={documentsByCategory[category]}
                                config={documentTableConfig}
                                showHeader={true}
                                documentActions={renderDocumentActions}
                                documentControls={renderDocumentControls}
                            />
                        ),
                    },
                ]

                return (
                    <Collapse
                        key={category}
                        defaultActiveKey={category}
                        items={items}
                        className={documentSectionStyle.section}
                    />
                )
            })}
        </>
    )
}

const renderDocumentActions = (props: DocumentActionsProps) => <DocumentActions {...props} />
const renderDocumentControls = (props: DocumentControlsProps) => <DocumentControls {...props} />

const documentTableConfig: DocumentTableProps['config'] = {
    columns: {
        participantName: false,
        documentName: 'SHORT',
        createdAt: true,
    },
}
