import { z } from 'zod'

import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

export const ledgerTransactionStatusSchema = z.enum(['UNCOMMITTED', 'COMMITTED'])

export type LedgerTransactionStatus = z.infer<typeof ledgerTransactionStatusSchema>

const ledgerTransactionStatuses: Record<KnownLocale, Record<LedgerTransactionStatus, string>> = {
    FR: {
        COMMITTED: 'Confirmé',
        UNCOMMITTED: 'Non-confirmé',
    },
    EN: {
        COMMITTED: 'Committed',
        UNCOMMITTED: 'Uncommitted',
    },
    // TRANSLATE: translate
    IT: {
        COMMITTED: 'Committed',
        UNCOMMITTED: 'Uncommitted',
    },
}

export const ledgerTransactionStatusLookup = new LookupList(ledgerTransactionStatuses)
