import { Layout as BaseLayout } from 'antd'
import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

import { colors } from '@publica/ui-common-styles'
import { FC } from '@publica/ui-common-utils'

import { Header } from '../Header'
import { VerticalSpacer } from '../VerticalSpacer'

const version = `v${__VERSION__}`

const useStyles = createUseStyles({
    layoutContent: {
        padding: [0, 50],
        paddingTop: 20,
    },
    layout: {
        minHeight: ['100vh', '!important'],
    },
    layoutFooter: {
        textAlign: 'center',
        color: colors.grey5,
    },
})

type LayoutProps = {
    logo?: ReactNode
    footerChildren?: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children, footerChildren, logo }) => {
    const styles = useStyles()

    return (
        <BaseLayout className={`layout ${styles.layout}`}>
            <Header logo={logo} />
            <BaseLayout.Content className={styles.layoutContent}>
                <VerticalSpacer size={20}>{children}</VerticalSpacer>
            </BaseLayout.Content>
            <BaseLayout.Footer className={styles.layoutFooter}>
                <VerticalSpacer size={10}>
                    {footerChildren}
                    <div>{version}</div>
                </VerticalSpacer>
            </BaseLayout.Footer>
        </BaseLayout>
    )
}
