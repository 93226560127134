import type { OperationStatus } from '@publica/api-graphql'
import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const operationStatuses: Record<KnownLocale, Record<OperationStatus, string>> = {
    FR: {
        OPEN: 'En cours',
        CLOSED: 'Clôturée',
        DRAFT: 'Brouillon',
    },
    EN: {
        OPEN: 'Active',
        CLOSED: 'Closed',
        DRAFT: 'Draft',
    },
    // TRANSLATE: translate
    IT: {
        OPEN: 'Active',
        CLOSED: 'Closed',
        DRAFT: 'Draft',
    },
}

export const operationStatusLookup = new LookupList(operationStatuses)
