import { Alert, Col, Divider, Row } from 'antd'
import groupBy from 'lodash/groupBy'
// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
import reduce from 'lodash/reduce'
import { ReactElement, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { To, useNavigate } from 'react-router-dom'

import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, VerticalSpacer } from '@publica/ui-web-components'

import {
    MissingAttachmentsNotification,
    MissingValuesNotification,
    RequiredSignaturesNotification,
    Notification as TNotification,
} from '../../types'
import { participantAttachmentsUrl, participantDocumentsUrl, participantEditProfileUrl } from '../../utils'

type Participant = {
    id: string
}

type NotificationGroups = {
    MissingValuesNotification?: MissingValuesNotification[]
    RequiredSignaturesNotification?: RequiredSignaturesNotification[]
    MissingAttachmentsNotification?: MissingAttachmentsNotification[]
}

export type NotificationsProps = {
    participant: Participant
    notifications: TNotification[]
}

export const Notifications: FC<NotificationsProps> = ({ participant, notifications }) => {
    const groupedNotifications: NotificationGroups = useMemo(
        () => groupBy(notifications, notification => notification.__typename),
        [notifications]
    )

    const notificationEls = useMemo(
        () =>
            reduce(
                groupedNotifications,
                (elements: ReactElement[], notifications, key) => {
                    switch (key as keyof NotificationGroups) {
                        case 'MissingValuesNotification':
                            return [
                                ...elements,
                                <MissingValuesNotifications
                                    participant={participant}
                                    key="missingValues"
                                    notifications={notifications as MissingValuesNotification[]}
                                />,
                            ]
                        case 'RequiredSignaturesNotification':
                            return [
                                ...elements,
                                <RequiredSignaturesNotifications
                                    participant={participant}
                                    key="requiredSignatures"
                                    notifications={notifications as RequiredSignaturesNotification[]}
                                />,
                            ]
                        case 'MissingAttachmentsNotification':
                            return [
                                ...elements,
                                <MissingAttachmentsNotifications
                                    participant={participant}
                                    key="missingAttachments"
                                    notifications={notifications as MissingAttachmentsNotification[]}
                                />,
                            ]
                    }
                },
                []
            ),
        [groupedNotifications, participant]
    )

    if (!(notifications.length > 0)) {
        return null
    }

    return (
        <>
            <Row justify="center">
                <Col span={16}>
                    <VerticalSpacer size={15}>{notificationEls}</VerticalSpacer>
                </Col>
            </Row>
            <Divider />
        </>
    )
}

const useRequiredSignaturesTranslation = createUseTranslation({
    FR: {
        message_one: `Un document est en attente de signature. Un lien securisé pour procéder à la signature vous a été transmis par email.`,
        message_other: `{{count}} documents sont en attente de signature. Un lien securisé pour procéder à la signature vous a été transmis par email.`,
    },
    EN: {
        message_one: 'A document is awaiting your signature. A secure link has been sent to you via email.',
        message_other: `{{count}} documents are awaiting your signature. A secure link has been sent to you via email.`,
    },
})

type RequiredSignaturesNotificationsProps = {
    participant: Participant
    notifications: RequiredSignaturesNotification[]
}

const RequiredSignaturesNotifications: FC<RequiredSignaturesNotificationsProps> = ({ participant, notifications }) => {
    const count = notifications.reduce((count, notification) => count + notification.count, 0)
    const { t } = useRequiredSignaturesTranslation()

    const message = t('message', { count })
    const url = participantDocumentsUrl(participant)

    return <Notification url={url} message={message} />
}

const useMissingValuesTranslation = createUseTranslation({
    FR: {
        provideInfo: `Compléter votre fiche d'informations`,
        incompleteInfo: `Votre fiche d'informations est incomplète`,
    },
    EN: {
        provideInfo: `Provide your personal information`,
        incompleteInfo: `Your personal information is incomplete`,
    },
})

type MissingValuesNotificationsProps = {
    participant: Participant
    notifications: MissingValuesNotification[]
}

const MissingValuesNotifications: FC<MissingValuesNotificationsProps> = ({ participant }) => {
    const { t } = useMissingValuesTranslation()

    const buttonText = t('provideInfo')
    const message = t('incompleteInfo')
    const url = participantEditProfileUrl(participant)

    return <Notification url={url} message={message} buttonText={buttonText} />
}

const useMissingAttachmentsTranslation = createUseTranslation({
    FR: {
        message_one: 'Un document KYC est manquant',
        message_other: `{{count}} documents KYC sont manquants`,
        transmit: 'Transmettre vos documents KYC',
    },
    EN: {
        message_one: 'An KYC document is missing',
        message_other: '{{count}} KYC documents are missing',
        transmit: 'Upload your KYC documents',
    },
})

type MissingAttachmentsNotificationsProps = {
    participant: Participant
    notifications: MissingAttachmentsNotification[]
}

const MissingAttachmentsNotifications: FC<MissingAttachmentsNotificationsProps> = ({ participant, notifications }) => {
    const count = notifications.reduce((count, notification) => count + notification.count, 0)
    const { t } = useMissingAttachmentsTranslation()

    const message = t('message', { count })

    const buttonText = t('transmit')
    const url = participantAttachmentsUrl(participant)

    return <Notification url={url} message={message} buttonText={buttonText} />
}

const useAlertStyles = createUseStyles({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
})

type NotificationProps = {
    url: To
    message: string
    buttonText?: string
}

const Notification: FC<NotificationProps> = ({ url, message, buttonText }) => {
    const navigate = useNavigate()
    const styles = useAlertStyles()

    const onClick = useCallback(() => {
        navigate(url)
    }, [navigate, url])

    const alertMessage = useMemo(
        () => (
            <Row justify="space-between">
                <Col className={styles.message}>{message}</Col>
                {buttonText === undefined ? null : (
                    <Col>
                        <ActionButton size="middle" onClick={onClick}>
                            {buttonText}
                        </ActionButton>
                    </Col>
                )}
            </Row>
        ),
        [buttonText, message, onClick, styles.message]
    )

    return <Alert type="warning" showIcon message={alertMessage} />
}
