import { Alert, Card, Divider } from 'antd'
import isNil from 'lodash/isNil'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { operationIsOpen } from '@publica/common'
import { GraphQLValue, Value, WithValues, graphQLValueToValue } from '@publica/render'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { FC } from '@publica/ui-common-utils'
import { ActionButton, ShowParticipantValues, Spinner } from '@publica/ui-web-components'
import { utils } from '@publica/ui-web-styles'
import { buildMap } from '@publica/utils'

import {
    FieldGroup,
    FieldParameters,
    LocalizedString,
    OperationStatus,
    useGetValuesForParticipantQuery,
} from '../../../../data'
import { Notifications } from '../../../components'
import { Notification } from '../../../types'
import { useParticipationContext } from '../../context'

const accountOwnerFieldGroups: FieldGroup[] = ['ACCOUNT_HOLDER_INFORMATION']
const infoFieldGroups: FieldGroup[] = ['PERSONAL_INFORMATION', 'BANKING_INFORMATION']

export const ShowProfile: FC = () => {
    const ctx = useParticipationContext()

    const { data, loading } = useGetValuesForParticipantQuery({
        variables: { participantId: ctx.participant.id },
        pollInterval: __SLOW_POLLING__,
    })

    const participant = data?.participant

    if (loading || isNil(participant)) {
        return <Spinner />
    }

    const operation = participant?.operation

    return <ShowProfileCard operation={operation} participant={participant} />
}

const useShowProfileCardTranslation = createUseTranslation({
    FR: {
        personalInformation: `Fiche d'informations`,
        updatePersonalInformation: `Mettre à jour votre fiche d'informations`,
        updateAccountInformation: `Mettre à jour vos informations de compte C-Level Partners`,
        divergedInfoWarning: `Vos informations de compte sont différentes de celles de votre fiche d'information`,
    },
    EN: {
        personalInformation: 'Personal information',
        updatePersonalInformation: 'Update your personal information',
        updateAccountInformation: 'Update your account information',
        divergedInfoWarning: `Your account information differs from the provided personal information`,
    },
})

type Field = {
    key: string
    name: LocalizedString
    group: FieldGroup
    parameters: FieldParameters
}

type Operation = {
    id: string
    status: OperationStatus
}

type Participant = {
    id: string
    notifications: Notification[]
    values: GraphQLValue[]
    fields: Field[]
}

type ShowProfileCardProps = {
    operation: Operation
    participant: Participant
}

const ShowProfileCard: FC<ShowProfileCardProps> = ({ operation, participant }) => {
    const styles = utils.useControlsStyles()
    const closed = !operationIsOpen(operation)
    const { t } = useShowProfileCardTranslation()

    const participantValues: WithValues<Participant> = useMemo(
        () => ({
            ...participant,
            values: participant.values.map(v => graphQLValueToValue(v) as Value),
        }),
        [participant]
    )

    const notifications = useMemo(
        () =>
            participant.notifications.filter(
                notification =>
                    !closed &&
                    notification.__typename === 'MissingValuesNotification' &&
                    notification.group !== 'FINANCIAL_INFORMATION'
            ),
        [closed, participant.notifications]
    )

    const navigate = useNavigate()

    const updatePersonalInformation = useCallback(() => {
        navigate('edit')
    }, [navigate])

    const updateAccount = useCallback(() => {
        navigate('/account')
    }, [navigate])

    // FIXME-P2(manager-ui): remove valuesHaveDiverged
    // FIXME-P2(manager-ui): sync with account
    // FIXME-P2(manager-ui): sync with legal entity
    const valuesHaveDiverged = useMemo(() => {
        const accountKeys: [string, string, 'TextValue' | 'LookupValue'][] = [
            ['accountOwnerFirstName', 'firstName', 'TextValue'],
            ['accountOwnerLastName', 'lastName', 'TextValue'],
            ['accountOwnerTitle', 'title', 'LookupValue'],
        ]

        const valueByKey = buildMap('key', participantValues.values)

        return accountKeys.some(([accountKey, infoKey]) => {
            const accountValue = valueByKey[accountKey]
            const infoValue = valueByKey[infoKey]

            // Really hacky, luckly it's temp code
            if (accountValue !== undefined && infoValue !== undefined) {
                if (accountValue.type === 'TextValue' && infoValue.type === 'TextValue') {
                    return accountValue.textValue !== infoValue.textValue
                } else if (accountValue.type === 'LookupValue' && infoValue.type === 'LookupValue') {
                    return accountValue.lookupValue.key !== infoValue.lookupValue.key
                }
            }

            return false
        })
    }, [participantValues.values])

    return (
        <Card title={t('personalInformation')}>
            <Notifications notifications={notifications} participant={participant} />
            <div className={styles.controls}>
                {/* FIXME-P2(manager-ui): this will need to be conditional when there are multiple reps */}
                <ActionButton size="middle" onClick={updateAccount} disabled={closed}>
                    {t('updateAccountInformation')}
                </ActionButton>
            </div>
            <ShowParticipantValues
                fieldGroups={accountOwnerFieldGroups}
                participant={participantValues}
                fields={participant.fields}
            />
            <Divider />
            {/* FIXME-P2(manager-ui): remove diverged warning */}
            {valuesHaveDiverged ? (
                <>
                    <Alert message={t('divergedInfoWarning')} />
                    <Divider />
                </>
            ) : null}
            <div className={styles.controls}>
                <ActionButton size="middle" onClick={updatePersonalInformation} disabled={closed}>
                    {t('updatePersonalInformation')}
                </ActionButton>
            </div>
            <ShowParticipantValues
                fieldGroups={infoFieldGroups}
                participant={participantValues}
                fields={participant.fields}
            />
        </Card>
    )
}
