import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { FC } from '@publica/ui-common-utils'
import { Account as InnerAccount, UpdateAccountDelegate } from '@publica/ui-web-components'

import { useCheckAccountEmailLazyQuery, useUpdateAccountMutation } from '../../../data'

// This is the depth when the page is first loaded
const sessionDepthAtStart = window.history.length

export const Account: FC = () => {
    const navigate = useNavigate()

    const [checkAccountEmailQuery] = useCheckAccountEmailLazyQuery()
    const [updateAccountMutation] = useUpdateAccountMutation()

    const backOrHome = useCallback(() => {
        // If the depth is greater than when we first loaded, we've
        // seen more than one page on the site
        if (window.history.length > sessionDepthAtStart) {
            navigate(-1)
        } else {
            navigate('/')
        }
    }, [navigate])

    const delegate: UpdateAccountDelegate = useMemo(
        () => ({
            checkEmailIsUnique: async email => {
                const { data } = await checkAccountEmailQuery({
                    variables: {
                        email,
                    },
                })
                return data?.accountByEmail?.id === undefined
            },
            onCancel: () => {
                backOrHome()
            },
            onUpdate: async (id, account) => {
                await updateAccountMutation({
                    variables: {
                        id,
                        account,
                    },
                })
            },
            onUpdateComplete: () => {
                backOrHome()
            },
        }),
        [backOrHome, checkAccountEmailQuery, updateAccountMutation]
    )

    return <InnerAccount updateAccount={delegate} mutable />
}
