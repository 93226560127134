import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const languages: Record<KnownLocale, Record<KnownLocale, string>> = {
    FR: {
        FR: 'Français',
        EN: 'Anglais',
        IT: 'Italien',
    },
    EN: {
        FR: 'French',
        EN: 'English',
        IT: 'Italian',
    },
    IT: {
        FR: 'Francese',
        EN: 'Inglese',
        IT: 'Italiano',
    },
}

export const languagesLookup = new LookupList(languages)
