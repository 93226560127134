import { RollbackOutlined } from '@ant-design/icons'
import { Button, Col, ConfigProvider, Form, Input, InputRef, Row, Space, ThemeConfig } from 'antd'
import { Rule } from 'antd/lib/form'
import base64 from 'base-64'
import isNil from 'lodash/isNil'
import times from 'lodash/times'
import {
    ChangeEventHandler,
    FC,
    KeyboardEventHandler,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { createUseStyles } from 'react-jss'
import { useSearchParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { Token, useAuthState } from '@publica/ui-common-auth'
import { createUseTranslation } from '@publica/ui-common-i18n'
import { useUnauthenticatedApiClient } from '@publica/ui-common-network'
import { colors } from '@publica/ui-common-styles'
import { useAsyncCallback } from '@publica/ui-common-utils'
import { ActionButton, icons } from '@publica/ui-web-components'
import { useCommonRules } from '@publica/ui-web-utils'
import { assert } from '@publica/utils'

import { useTermsTranslations } from '../terms'
import { ManagerAuthState } from './auth'

const useLoginStyles = createUseStyles({
    email: {
        width: 300,
    },
    instructions: {
        marginBottom: 25,
        color: '#FFF',
    },
    login: {
        padding: [15, 0],
        color: '#FFF',
    },
    termsSeparator: {
        borderBottom: '1px solid',
        borderBottomColor: colors.primary,
        padding: [15, 0],
    },
    terms: {
        marginTop: 15,
        fontSize: '0.9em',
        '& a': {
            color: colors.grey6,
        },
    },
})

const useEncodedSignInCode = () => {
    const [searchParams] = useSearchParams()
    const encodedSignInCode = searchParams.get('signInCode')

    if (encodedSignInCode === null) {
        return undefined
    }

    try {
        const [email, code] = base64.decode(encodedSignInCode).split(':')

        assert.defined(email)

        return { email, code: code === undefined ? undefined : parseInt(code) }
    } catch (e) {
        return undefined
    }
}

const loginTheme: ThemeConfig = {
    components: {
        Input: {
            addonBg: colors.primary,
            colorBorder: colors.primary,
            colorText: '#000',
            colorErrorText: '#FFF',
            colorErrorBorder: colors.primary,
        },
    },
}

export const Login: FC = () => {
    const signInCode = useEncodedSignInCode()
    const [providedEmail, setProvidedEmail] = useState<string | undefined>()
    const [codeRequested, setCodeRequested] = useState(signInCode?.code !== undefined)
    const styles = useLoginStyles()
    const { t } = useTermsTranslations()

    const email = providedEmail ?? signInCode?.email

    const onCodeCreated = useCallback((email: string) => {
        setCodeRequested(true)
        setProvidedEmail(email)
    }, [])

    const onCancel = useCallback(() => {
        setCodeRequested(false)
        setProvidedEmail(undefined)
    }, [])

    return (
        <div className={styles.login}>
            <ConfigProvider theme={loginTheme}>
                {codeRequested && email !== undefined ? (
                    <SignInCode onCancel={onCancel} email={email} code={signInCode?.code} />
                ) : (
                    <EmailLogin onCodeCreated={onCodeCreated} email={email} />
                )}
            </ConfigProvider>
            <div className={styles.termsSeparator} />
            <div className={styles.terms}>
                <Link to="/terms">{t('terms')}</Link>
            </div>
        </div>
    )
}

type EmailLoginForm = {
    email: string
}

type EmailLoginProps = {
    onCodeCreated: (email: string) => void
    email?: string
}

const useAuthTranslation = createUseTranslation({
    FR: {
        'email.instructions': 'Veuillez saisir votre adresse email pour vous connecter',
        'email.requestCode': `Demander un code d'accès`,
        'code.sent': 'Un code PIN a été envoyé à votre adresse email, veuillez le saisir ci-dessous',
        'code.error.invalid': `Le code n'est pas valide`,
        'code.error.enterCode': 'Vous devez saisir un code',
    },
    EN: {
        'email.instructions': 'Please provide your email to log in',
        'email.requestCode': `Request an access code`,
        'code.sent': 'An access code has been sent to your email address, please enter it below',
        'code.error.invalid': 'The code is incorrect',
        'code.error.enterCode': 'You must provide a code',
    },
})

const EmailLogin: FC<EmailLoginProps> = ({ onCodeCreated, email }) => {
    const styles = useLoginStyles()
    const [form] = Form.useForm<EmailLoginForm>()
    const [submitting, setSubmitting] = useState(false)
    const [mounted, setMounted] = useState(false)
    const client = useUnauthenticatedApiClient()
    const rules = useCommonRules()
    const { t } = useAuthTranslation()

    useEffect(() => {
        setMounted(true)
        return () => {
            setMounted(false)
        }
    }, [])

    const submit = useAsyncCallback(async () => {
        try {
            await form.validateFields()
            setSubmitting(true)

            const { email } = form.getFieldsValue()
            await client.auth.createSignInCode({ email })

            onCodeCreated(email)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            if (mounted) {
                setSubmitting(false)
            }
        }
    }, [client.auth, form, mounted, onCodeCreated])

    const initialValues = useMemo(
        () => ({
            email,
        }),
        [email]
    )

    return (
        <Form form={form} initialValues={initialValues}>
            <Form.Item hasFeedback>
                <div className={styles.instructions}>{t('email.instructions')}</div>
                <Form.Item name="email" noStyle rules={rules.requiredEmail} validateTrigger="onBlur">
                    <Input addonBefore="@" className={styles.email} placeholder="email" disabled={submitting} />
                </Form.Item>
            </Form.Item>
            <ActionButton size="middle" inProgress={submitting} icon={icons.Login} onClick={submit}>
                {t('email.requestCode')}
            </ActionButton>
        </Form>
    )
}

type SignInCodeForm = {
    code: number
}

const pinTriggers: string[] = []

const backIcon = <RollbackOutlined />

type SignInCodeProps = {
    onCancel: () => void
    email: string
    code?: number
}

const pinLength = 6

const SignInCode: FC<SignInCodeProps> = ({ onCancel, email, code }) => {
    const [_, setSearchParams] = useSearchParams()
    const styles = useLoginStyles()

    const [mounted, setMounted] = useState(false)

    const initialValues = useMemo(
        () => ({
            code,
        }),
        [code]
    )

    useEffect(() => {
        setMounted(true)
        return () => {
            setMounted(false)
        }
    }, [])

    const [form] = Form.useForm<SignInCodeForm>()
    const [submitting, setSubmitting] = useState(false)

    const client = useUnauthenticatedApiClient()
    const { state } = useAuthState<Token, ManagerAuthState>()

    const { t } = useAuthTranslation()

    // Allow you to paste the pin from anywhere
    useEffect(() => {
        const pasteHandler = (ev: ClipboardEvent) => {
            const data = ev.clipboardData?.getData('text')
            if (data === undefined || data.length !== pinLength || !data.match(/^[0-9]+$/)) {
                return
            }

            form.setFieldsValue({ code: parseInt(data) })
        }

        document.addEventListener('paste', pasteHandler)

        return () => {
            document.removeEventListener('paste', pasteHandler)
        }
    }, [form])

    const submit = useAsyncCallback(async () => {
        try {
            await form.validateFields()

            setSubmitting(true)

            const { code } = form.getFieldsValue()

            const token = await client.auth.getTokenForSignInCode({ email, signInCode: code }).catch(e => {
                form.setFields([
                    {
                        name: 'code',
                        errors: [t('code.error.invalid')],
                    },
                ])
                throw e
            })

            await state.login(token)
            setSearchParams({}, { replace: true })
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            // We need this check as a successful login
            // will unmount this component
            if (mounted) {
                setSubmitting(false)
            }
        }
    }, [client.auth, email, form, mounted, setSearchParams, state, t])

    const pinRules = useMemo<Rule[]>(
        () => [
            {
                required: true,
                message: t('code.error.enterCode'), //'Vous devez saisir votre code',
            },
        ],
        [t]
    )

    return (
        <Form form={form} initialValues={initialValues}>
            <Form.Item>
                <div className={styles.instructions}>{t('code.sent')}</div>
                <Form.Item name="code" noStyle rules={pinRules} validateTrigger={pinTriggers}>
                    <PINInput disabled={submitting} digits={pinLength} />
                </Form.Item>
            </Form.Item>
            <Space>
                <Button size="middle" icon={backIcon} onClick={onCancel}>
                    {t('back')}
                </Button>
                <ActionButton size="middle" inProgress={submitting} icon={icons.Login} onClick={submit}>
                    {t('login')}
                </ActionButton>
            </Space>
        </Form>
    )
}

type PINInputProps = {
    digits?: number
    value?: number
    onChange?: (val: number | undefined) => void
    disabled?: boolean
}

const PINInput: FC<PINInputProps> = ({ digits = 6, disabled, value, onChange }) => {
    const refs = useRef<(InputRef | null)[]>([])
    const [pin, setPin] = useState<(number | undefined)[]>([])

    // When the value is passed down, break it into
    // individual digits
    useEffect(() => {
        if (value !== undefined) {
            setPin(() => {
                const pin: number[] = []
                let rem = value
                while (rem > 0) {
                    pin.push(rem % 10)
                    rem = Math.floor(rem / 10)
                }
                return pin.reverse()
            })
        }
    }, [value])

    // If the pin is complete, trigger the parent onChange
    useEffect(() => {
        if (onChange === undefined) {
            return
        }

        let assembledPin = 0
        const maxIndex = digits - 1
        for (let i = maxIndex; i >= 0; i--) {
            const val = pin[i]

            // Bail if one of the digits is invalid
            if (val === undefined) {
                onChange(undefined)
                return
            }
            assembledPin = assembledPin + val * Math.pow(10, maxIndex - i)
        }

        onChange(assembledPin)
    }, [digits, onChange, pin])

    // When a valid digit is provided, shift the focus to the next field
    const onChangeDigit = useCallback((index: number, val: number | undefined) => {
        setPin(current => {
            const updatedPin = [...current]
            updatedPin[index] = val
            return updatedPin
        })

        if (val === undefined) {
            return
        }

        const currentDigitEl = refs.current[index]
        const nextDigitEl = refs.current[index + 1]

        if (!isNil(currentDigitEl)) {
            currentDigitEl.blur()
        }

        if (!isNil(nextDigitEl)) {
            nextDigitEl.focus()
        }
    }, [])

    const onBackspace = useCallback((index: number) => {
        const previousDigitEl = refs.current[index - 1]

        if (!isNil(previousDigitEl)) {
            previousDigitEl.focus()
        }
    }, [])

    return (
        <Row gutter={10} align="middle" justify="center">
            {times(digits, idx => (
                <Col key={idx}>
                    <PINInputDigit
                        index={idx}
                        disabled={disabled}
                        value={pin[idx]}
                        onChange={onChangeDigit}
                        // eslint-disable-next-line react/jsx-no-bind,react-perf/jsx-no-new-function-as-prop
                        inputRef={el => {
                            refs.current[idx] = el
                        }}
                        onBackspace={onBackspace}
                    />
                </Col>
            ))}
        </Row>
    )
}

const usePinDigitStyles = createUseStyles({
    digit: {
        width: 40,
        textAlign: 'center',
        fontSize: '2em',
        borderRadius: 10,
    },
})

type PINInputDigitProps = {
    index: number
    disabled?: boolean
    onChange: (index: number, val: number | undefined) => void
    value?: number
    onBackspace: (index: number) => void
    inputRef?: React.Ref<InputRef>
}

const PINInputDigit: FC<PINInputDigitProps> = ({ index, disabled, onChange, inputRef, onBackspace, value }) => {
    const [inputValue, setInputValue] = useState<number | undefined>(value)
    const styles = usePinDigitStyles()

    useEffect(() => {
        setInputValue(value)
    }, [value])

    // Only allow single digit inputs
    const onlyAcceptNumber = useCallback((ev: SyntheticEvent & { data?: string }) => {
        const { data } = ev
        if (data === undefined || !data.match(/^[0-9]$/)) {
            ev.preventDefault()
            ev.stopPropagation()
        }
    }, [])

    // Convert the input to a number
    const emitValue = useCallback<ChangeEventHandler<HTMLInputElement>>(
        ev => {
            const val = parseInt(ev.target.value)

            if (isNaN(val)) {
                onChange(index, undefined)
                setInputValue(undefined)
            } else {
                onChange(index, val)
                setInputValue(val)
            }
        },
        [index, onChange]
    )

    // When you press delete, first it will delete the value in the current
    // field, but if the field is empty it will move the focus to the previous
    // field
    const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
        ev => {
            if (ev.code === 'Backspace' && inputValue === undefined) {
                onBackspace(index)
            }
        },
        [index, inputValue, onBackspace]
    )

    return (
        <Input
            className={styles.digit}
            maxLength={1}
            disabled={disabled}
            autoComplete="off"
            onBeforeInput={onlyAcceptNumber}
            onChange={emitValue}
            onKeyDown={onKeyDown}
            value={inputValue}
            ref={inputRef}
        />
    )
}
