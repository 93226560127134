import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const booleans: Record<KnownLocale, Record<'TRUE' | 'FALSE', string>> = {
    FR: {
        TRUE: 'Oui',
        FALSE: 'Non',
    },
    EN: {
        TRUE: 'Yes',
        FALSE: 'No',
    },
    IT: {
        TRUE: 'Sì',
        FALSE: 'No',
    },
}

export const booleansLookup = new LookupList(booleans)
