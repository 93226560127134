import mapValues from 'lodash/mapValues'

import { LookupList, type LookupListData } from '../lookupList'
import countryData from './codes.json'

// FIXME-P2(rendering): nationalité gender

export const countryLookup = new LookupList(countryData.countries, ['FR', 'GB', 'IT'])
export const nationalityLookup = new LookupList(countryData.nationalities, ['FR', 'GB', 'IT'])

export type CountryCode = keyof (typeof countryData)['prefixes']

const createDiallingPrefixLabel = (country: string, code: CountryCode): CountryDiallingPrefix => ({
    prefix: `+${countryData.prefixes[code]}`,
    label: `${country} (+${countryData.prefixes[code]})`,
    code,
})

export type CountryDiallingPrefix = {
    prefix: string
    label: string
    code: CountryCode
}

const diallingPrefixLabels: LookupListData<CountryCode, CountryDiallingPrefix> = {
    FR: mapValues(countryData.countries.FR, (val, key) => createDiallingPrefixLabel(val, key as CountryCode)) as Record<
        CountryCode,
        CountryDiallingPrefix
    >,
    EN: mapValues(countryData.countries.EN, (val, key) => createDiallingPrefixLabel(val, key as CountryCode)) as Record<
        CountryCode,
        CountryDiallingPrefix
    >,
    IT: mapValues(countryData.countries.IT, (val, key) => createDiallingPrefixLabel(val, key as CountryCode)) as Record<
        CountryCode,
        CountryDiallingPrefix
    >,
}

export const diallingPrefixLookup = new LookupList(diallingPrefixLabels, ['FR', 'GB', 'IT'])

export const countryToDiallingPrefixMap = countryData.prefixes
