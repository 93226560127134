import { createContext, useContext, useMemo } from 'react'

import { FC } from '@publica/ui-common-utils'
import { assert } from '@publica/utils'

type Operation = {
    id: string
    name: string
}

type Participant = {
    id: string
}

type ParticipationContext = {
    operation: Operation
    participant: Participant
}

const context = createContext<ParticipationContext | undefined>(undefined)

export const useParticipationContext = () => {
    const pContext = useContext(context)
    assert.defined(pContext)
    return pContext
}

type ParticipationContextProps = ParticipationContext

export const ParticipationContext: FC<ParticipationContextProps> = ({ participant, operation, children }) => {
    const ctx = useMemo(() => ({ participant, operation }), [operation, participant])

    return <context.Provider value={ctx}>{children}</context.Provider>
}
