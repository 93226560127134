import React from 'react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

type TabProps = {
    label: React.ReactNode
    icon: React.ReactNode
}

const useTabStyles = createUseStyles({
    icon: {
        paddingRight: 5,
    },
})

export const Tab: FC<TabProps> = ({ icon, label }) => {
    const styles = useTabStyles()

    return (
        <span>
            <span className={styles.icon}>{icon}</span>
            {label}
        </span>
    )
}
