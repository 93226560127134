import { Client } from '@publica/api-client'
import { GetTokenResponse } from '@publica/common'
import { AccountTokenPair, BaseAuthState, Jwt } from '@publica/ui-common-auth'
import { logger } from '@publica/ui-common-logger'
import { useConfig } from '@publica/ui-common-utils'

const localStorageAccessTokenKey = 'accessToken'
const localStorageRefreshTokenKey = 'refreshToken'

export class ManagerAuthState extends BaseAuthState<Jwt> {
    private aToken: Jwt | undefined
    private rToken: string | undefined

    private readonly client: Client

    constructor(host: string) {
        super({ host })
        this.client = new Client(host)
        void this.initialize()
    }

    private async initialize(): Promise<void> {
        logger.log('Initializing ManagerAuthProvider')

        await this.performLogin(async () => {
            logger.log('Attempting login on load')

            const tokens = await this.readTokensFromStorage()
            const accountTokenPair = await this.updateTokens(tokens)

            if (!accountTokenPair.token.isValid()) {
                logger.log('Token needs refreshing')
                return await this.refreshToken()
            }

            logger.log('Found valid token on load')
            return accountTokenPair
        })
            .catch(() => {})
            .finally(() => {
                void this.markAsInitialized()
            })
    }

    async refreshToken(): Promise<AccountTokenPair<Jwt>> {
        return this.peformRefresh(async () => {
            if (this.rToken === undefined) {
                throw new Error('No refresh token')
            }

            const refreshed = await this.client.auth.refreshToken({ refreshToken: this.rToken })
            return this.updateTokens(refreshed)
        })
    }

    async login(tokens: GetTokenResponse): Promise<AccountTokenPair<Jwt>> {
        return this.performLogin(async () => this.updateTokens(tokens))
    }

    async logout(): Promise<void> {
        return this.performLogout(async () => this.clearTokens())
    }

    private async updateTokens(tokens: GetTokenResponse): Promise<AccountTokenPair<Jwt>> {
        logger.log('Updating tokens')

        this.aToken = Jwt.withDefaultSchema(tokens.accessToken)
        this.rToken = tokens.refreshToken

        window.localStorage.setItem(localStorageAccessTokenKey, tokens.accessToken)
        window.localStorage.setItem(localStorageRefreshTokenKey, tokens.refreshToken)

        return this.getAccountTokenPairForToken(this.aToken)
    }

    private async clearTokens(): Promise<void> {
        logger.log('Clearing tokens from storage')

        this.aToken = undefined
        this.rToken = undefined
        window.localStorage.removeItem(localStorageAccessTokenKey)
        window.localStorage.removeItem(localStorageRefreshTokenKey)
    }

    private async readTokensFromStorage(): Promise<GetTokenResponse> {
        logger.log('Reading tokens from storage')

        const accessToken = window.localStorage.getItem(localStorageAccessTokenKey)
        const refreshToken = window.localStorage.getItem(localStorageRefreshTokenKey)

        if (accessToken === null || refreshToken === null) {
            logger.log('No tokens found in storage')
            throw new Error('Unable to retrieve tokens from storage')
        }

        logger.log('Found tokens in storage')

        return { accessToken, refreshToken }
    }
}

let authState: ManagerAuthState | undefined

export const authStateBuilder = (): ManagerAuthState => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const config = useConfig()

    if (authState === undefined) {
        authState = new ManagerAuthState(config.apiHost)
    }

    return authState
}
