import type { FieldGroup } from '@publica/api-graphql'
import type { KnownLocale } from '@publica/locales'

import { LookupList } from './lookupList'

const fieldGroups: Record<KnownLocale, Record<FieldGroup, string>> = {
    FR: {
        ACCOUNT_HOLDER_INFORMATION: 'Informations Titulaire de Compte C-Level Partners',
        BANKING_INFORMATION: 'Informations Bancaires',
        FINANCIAL_INFORMATION: 'Informations Financières',
        PERSONAL_INFORMATION: 'Informations Personnelles',
        PLATFORM_INFORMATION: 'Informations Platforme',
    },
    EN: {
        ACCOUNT_HOLDER_INFORMATION: 'C-Level Partners Account Owner Information',
        BANKING_INFORMATION: 'Bank Information',
        FINANCIAL_INFORMATION: 'Financial Information',
        PERSONAL_INFORMATION: 'Personal Information',
        PLATFORM_INFORMATION: 'Platform Information',
    },
    // TRANSLATE: translate
    IT: {
        ACCOUNT_HOLDER_INFORMATION: 'C-Level Partners Account Owner Information',
        BANKING_INFORMATION: 'Bank Information',
        FINANCIAL_INFORMATION: 'Financial Information',
        PERSONAL_INFORMATION: 'Personal Information',
        PLATFORM_INFORMATION: 'Platform Information',
    },
}

export const fieldGroupLookup = new LookupList(fieldGroups)
